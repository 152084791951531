import ress from '@/utils/ress';
export default {
    address(provinceId, cityId) {
        if (provinceId) {
            let province = ress[100000];
            let provinceKey = '';
            let provinceData = '';
            let city = '';
            if (provinceId) {
                for (var key in province) {
                    if (key == provinceId) {
                        provinceData = province[key];
                        provinceKey = key;
                    }
                }
            }
            for (var keys in ress[provinceKey]) {
                if (keys == cityId) {
                    city = ress[provinceKey][keys];
                }
            }
            if (cityId) {
                return provinceData + ',' + city;
            }
            return provinceData
        } else {
            return '- -'
        }
    },
    cascader() {
        let options = []
        for (const key in ress[100000]) {
            options.push({
                value: key,
                label: ress[100000][key],
            })
        }
        options.forEach(item => {
            for (const key in ress) {
                if (item.value == key) {
                    item.children = []
                    for (const val in ress[key]) {
                        item.children.push({
                            value: val,
                            label: ress[key][val]
                        })
                    }
                }

            }
        })

        return options
    },

}