import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        data: {},
        newData: {},
        isRepeat: false, //是否有重复数据
        libraryId: '',
        clueCategory: '',//转换类型
        type: '',//获取SDR还是MDR
        isDist: false,//是否为分配
        distUser: '',//分配人ID
    },
    //状态管理
    mutations: {
        //原有数据
        detailsData(state, params) {
            // console.log(JSON.parse(params),'原有');
            state.data = JSON.parse(params)
        },
        // 新的数据
        newData(state, params) {
            // console.log(JSON.parse(params),'新的');
            state.newData = JSON.parse(params)
        },
        // 是否有重复数据
        isRepeatData(state, params) {
            // console.log(params);
            state.isRepeat = params
        },
        // 资料库ID
        libraryId(state, params) {
            // console.log(params);
            state.libraryId = params
        },
        // 转换类型
        clueCategory(state, params) {
            // console.log(params);
            state.clueCategory = params
        },
        getType(state, params) {
            // console.log(params);
            state.type = params
        },
        getIsDist(state, params) {
            // console.log(params);
            state.isDist = params
        },
        // 分配人ID
        getDistUser(state, params) {
            // console.log(params);
            state.distUser = params
        },
        // 清空数据
        emptyData(state) {
            state.data = {}
            state.newData = {}
            state.isRepeat = false
            state.libraryId = ''
            state.clueCategory = ''
            state.type = ''
            state.isDist = false
            state.distUser = ''
        }
    },
})
export default store
