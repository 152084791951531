export default {
    handleTime(time) {
        if (time) {
            let oldTimeFormat = new Date(time);
            let nowDate = new Date();
            let times = nowDate.getTime() - oldTimeFormat.getTime();
            let days = parseInt(times / (60 * 60 * 24 * 1000));
            if (days == 0) {
                return '今天' + time.substring(10, 16)
            } else if (days == 1) {
                return '昨天' + time.substring(10, 16)
            } else {
                return time.substring(0, 10)
            }
        } else {
            return '- -'
        }
    },
    formatDate(time) {
        var date = new Date(time);
        var month = date.getMonth() + 1;
        var strDate = date.getDate();
        return month + "月" + strDate + "日"
    },
    getFormatDate() {
        var date = new Date();
        var month = date.getMonth() + 1;
        var strDate = date.getDate();
        var hours = date.getHours()
        var minutes = date.getMinutes();
        var seconds = date.getSeconds()

        if (month >= 1 && month <= 9) {
            month = "0" + month;
        }
        if (strDate >= 0 && strDate <= 9) {
            strDate = "0" + strDate;
        }
        if (hours >= 0 && hours <= 9) {
            hours = "0" + hours;
        }
        if (minutes >= 0 && minutes <= 9) {
            minutes = "0" + minutes;
        }
        if (seconds >= 0 && seconds <= 9) {
            seconds = "0" + seconds;
        }
        var currentDate = date.getFullYear() + "-" + month + "-" + strDate
            + " " + hours + ":" + minutes + ":" + seconds;
        return currentDate;
    }
}