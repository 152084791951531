export default {
    mailboxbrand(arr) {
        let list = [
            {
                name: '阿里云收费版',
                value: 8,
            },
            {
                name: '阿里云免费版',
                value: 35,
            },
            {
                name: '腾讯收费版',
                value: 9,
            },
            {
                name: '腾讯免费版',
                value: 10,
            },
            {
                name: '263',
                value: 11,
            },
            {
                name: '网易收费版',
                value: 12,
            },
            {
                name: '网易免费版',
                value: 13,
            },
            {
                name: '35互联',
                value: 14,
            },
            {
                name: '美橙互联',
                value: 15,
            },
            {
                name: '盈世',
                value: 16,
            },
            {
                name: '电信',
                value: 17,
            },
            {
                name: '中企动力',
                value: 18,
            },
            {
                name: '中资源',
                value: 19,
            },
            {
                name: '网盛生意宝',
                value: 20,
            },
            {
                name: '自建',
                value: 21,
            },
            {
                name: '爱思华宝',
                value: 22,
            },
            {
                name: '新网',
                value: 23,
            },
            {
                name: '磐邮',
                value: 24,
            },
            {
                name: 'FangMail',
                value: 25,
            },
            {
                name: 'U-Mail',
                value: 26,
            },
            {
                name: '新浪',
                value: 27,
            },
            {
                name: '雅虎',
                value: 28,
            },
            {
                name: 'Coremail',
                value: 29,
            },
            {
                name: '论客',
                value: 30,
            },
            {
                name: '微软/outlook',
                value: 31,
            },
            {
                name: '时代互联',
                value: 32,
            },
            {
                name: '云邮',
                value: 33,
            },
            {
                name: '无邮箱',
                value: 34,
            },
        ]
        return (arr = list)
    },
    clueSource(arr) {
        let obj = [
            {
                name: '伽搜-百度',
                value: 30,
            },
            {
                name: '伽搜-360',
                value: 31,
            },
            {
                name: '伽搜-搜狗',
                value: 32,
            },
            {
                name: '伽搜-非搜索引擎',
                value: 33,
            },
            {
                name: '伽搜-直接访问',
                value: 34,
            },
            {
                name: '伽湾-百度',
                value: 35,
            },
            {
                name: '伽湾-360',
                value: 36,
            },
            {
                name: '伽湾-搜狗',
                value: 37,
            },
            {
                name: '伽湾-非搜索引擎',
                value: 38,
            },
            {
                name: '伽湾-直接访问',
                value: 39,
            },
            {
                name: '商搜-百度',
                value: 40,
            },
            {
                name: '商搜-360',
                value: 41,
            },
            {
                name: '商搜-搜狗',
                value: 42,
            },
            {
                name: '商搜-非搜索引擎',
                value: 43,
            },
            {
                name: '商搜-直接访问',
                value: 44,
            },
            {
                name: '嘉丁-百度',
                value: 45,
            },
            {
                name: '嘉丁-360',
                value: 46,
            },
            {
                name: '嘉丁-搜狗',
                value: 47,
            },
            {
                name: '嘉丁-非搜索引擎',
                value: 48,
            },
            {
                name: '嘉丁-直接访问',
                value: 49,
            },
            {
                name: '布谷-百度',
                value: 50,
            },
            {
                name: '布谷-360',
                value: 51,
            },
            {
                name: '布谷-搜狗',
                value: 52,
            },
            {
                name: '布谷-非搜索引擎',
                value: 53,
            },
            {
                name: '布谷-直接访问',
                value: 54,
            },
            {
                name: '三木-百度',
                value: 55,
            },
            {
                name: '三木-360',
                value: 56,
            },
            {
                name: '三木-搜狗',
                value: 57,
            },
            {
                name: '三木-非搜索引擎',
                value: 58,
            },
            {
                name: '三木-直接访问',
                value: 59,
            },


            {
                name: '钱邮-360',
                value: 101,
            },
            {
                name: '钱邮-百度',
                value: 102,
            },
            {
                name: '钱邮-搜狗',
                value: 103,
            },
            {
                name: '微易-360',
                value: 104,
            },
            {
                name: '微易-百度',
                value: 105,
            },
            {
                name: '微易-搜狗',
                value: 106,
            },
            {
                name: '微易-直接访问',
                value: 107,
            },
            {
                name: '钱邮-直接访问',
                value: 108,
            },


            {
                name: '400电话',
                value: 6,
            },
            {
                name: '官网表单',
                value: 7,
            },
            {
                name: '邮件',
                value: 8,
            },
            {
                name: '老客介绍',
                value: 9,
            },
            {
                name: '自拓',
                value: 10,
            },
            {
                name: '微信扫一扫',
                value: 11,
            },
            {
                name: '在线留言',
                value: 12,
            },
            {
                name: '华为云邮箱',
                value: 14,
            },
            {
                name: '华为云建站',
                value: 15,
            },
            {
                name: '华为云服务器',
                value: 16,
            },
            {
                name: '其他',
                value: 13,
            },

        ]
        return (arr = obj)
    },
    productCategory(arr) {
        let obj = [
            {
                label: "腾讯企业邮箱",
                value: 1
            },
            {
                label: "阿里企业邮箱",
                value: 2
            },
            {
                label: "云产品",
                value: 3
            },
            {
                label: "网易企业邮箱",
                value: 4
            },
            {
                label: "网站",
                value: 5
            },
            {
                label: "投屏盒子",
                value: 6
            },
            {
                label: "微盘",
                value: 7
            },
            {
                label: "主机",
                value: 8
            },
            {
                label: "服务器",
                value: 9
            },
            {
                label: "会话存档",
                value: 10
            },
            {
                label: "企业微信",
                value: 11
            },
            {
                label: "域名",
                value: 12
            },
            {
                label: "飞连",
                value: 13
            }
        ]
        return (arr = obj)
    },
    // 投放渠道类型
    launchType(arr) {
        let obj = [
            {
                label: "百度",
                value: 1
            },
            {
                label: "360",
                value: 2
            },
            {
                label: "搜狗",
                value: 3
            },
        ]
        return (arr = obj)
    },
    // 投放广告类型
    launchAdvertType(arr) {
        let obj = [
            {
                label: "竞价",
                value: 1
            },
            {
                label: "品牌",
                value: 2
            },
            {
                label: "丝路",
                value: 3
            },
        ]
        return (arr = obj)
    },
    // 商机类型
    boType(arr) {
        let obj = [
            {
                label: "续费",
                value: 1
            },
            {
                label: "扩用户",
                value: 2
            },
            {
                label: "扩容续费",
                value: 3
            },
        ]
        return (arr = obj)
    },
    // 商机进度
    boSpeed(arr) {
        let obj = [
            {
                label: "跟进中",
                value: 1
            },
            {
                label: "方案确定",
                value: 2
            },
            {
                label: "签约",
                value: 3
            },
            {
                label: "回款",
                value: 4
            },
            {
                label: "流失",
                value: 5
            },
            {
                label: "放弃",
                value: 6
            },
        ]
        return (arr = obj)
    },
    // 已成交客户报备产品类型
    boProductType(arr) {
        let obj = [
            {
                label: '腾讯邮箱',
                value: 1
            },
            {
                label: '阿里邮箱',
                value: 2
            },
        ]
        return (arr = obj)
    },
    // 审批类型
    auditType() {
        let arr = [
            {
                value: 1,
                label: '全部',

            },
            {
                value: 2,
                label: '销售',
                children: [
                    {
                        value: 1,
                        label: '发票',
                    },
                    {
                        value: 2,
                        label: '报备延期',
                    }
                ],
            },
            {
                value: 3,
                label: '费用',
                children: [
                    {
                        value: 1,
                        label: '充值',
                    },
                    {
                        value: 2,
                        label: '申报',
                    },
                    {
                        value: 3,
                        label: '报销',
                    },
                ],
            },
        ]

        return arr
    },
    // 审批类型
    auditType2() {
        let arr = [
            {
                value: 1,
                label: '全部',

            },
            {
                value: 2,
                label: '销售',
                children: [
                    {
                        value: 1,
                        label: '发票',
                    },

                ],
            },
            {
                value: 3,
                label: '费用',
                children: [
                    {
                        value: 1,
                        label: '充值',
                    },
                    {
                        value: 2,
                        label: '申报',
                    },
                    {
                        value: 3,
                        label: '报销',
                    },
                ],
            },
        ]

        return arr
    }
}