import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    i: -1,
    labelList: [],
    type: '0'
  },       //状态管理
  mutations: {
    // 获取下标
    getIndex(state, params) {
      state.i = params
    },
    // 选择标签
    choiceLabel(state, params) {
      state.labelList = JSON.parse(params)
    },
    // 移除标签
    removeLabel(state, params) {
      let data = JSON.parse(params)
      for (let i in state.labelList) {
        if (state.labelList[i].id == data.id) {
          // console.log(state.labelList[i]);
          state.labelList.splice(i, 1)
        }
      }
    },
    // 区分新增删除
    mailType(state, params) {
      state.type = params
    },
    // 清空数据
    emptyData(state) {
      state.i = -1
      state.labelList = []

    }
  },   //修改state
  actions: {
  },    //异步操作
  modules: {}     //模块
})
export default store
