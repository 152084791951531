import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import VueRouter from 'vue-router'
import ElementUI from 'element-ui';
import Tree from './utils/tree';
import 'element-ui/lib/theme-chalk/index.css'

import columnWidth from './utils/column-width';
import tableDataHandle from './utils/clueTable'
import mailStatus from './utils/mailStatus'
import handTime from './utils/timeHandle'
import searchTime from './utils/searchTime'
import Ress from './utils/ress';
import addclue from './store/addclue.js'
import clueDetails from './store/clueDetails.js'
import addmail from './store/addmail.js'
import maildetails from './store/maildetails.js'
import Industry from './utils/industry';
import choiceLabel from './store/choiceLabel'
import formDetails from './store/formDetails'
import database from './store/database'
import address from './utils/address'
import Url from './utils/url';
import searchForm from './utils/searchForm'
import searchLabel from './store/searchLabel'
import csForm from './utils/csForm'
import Cost from "./utils/cost"
import MerchantJson from "./utils/merchantJson.js"
import TargetList from "./utils/targetList"
import Calculator from "./utils/calculator"
import Axios from './utils/achievement.js'
import Util from "./utils/util"

import { Message } from 'element-ui'
Vue.config.productionTip = false
Vue.prototype.$url = Url;
Vue.prototype.$searchForm = searchForm;
Vue.prototype.$csForm = csForm;
Vue.prototype.$TargetList = TargetList;
Vue.prototype.$Calculator = Calculator;
Vue.prototype.$Ress = Ress;
Vue.prototype.$Tree = Tree;
Vue.prototype.$Axios = Axios;
Vue.prototype.$address = address;
Vue.prototype.$columnWidth = columnWidth;
Vue.prototype.$tableDataHandle = tableDataHandle;
Vue.prototype.$MailStatus = mailStatus;
Vue.prototype.$handTime = handTime;
Vue.prototype.$searchTime = searchTime;
Vue.prototype.$addclue = addclue;
Vue.prototype.$clueDetails = clueDetails
Vue.prototype.$addmail = addmail
Vue.prototype.$maildetails = maildetails
Vue.prototype.$choiceLabel = choiceLabel
Vue.prototype.$formDetails = formDetails
Vue.prototype.$database = database
Vue.prototype.$searchLabel = searchLabel
Vue.prototype.$industry = Industry;
Vue.prototype.$cost = Cost;
Vue.prototype.$util = Util;
Vue.prototype.$merchantJson = MerchantJson;
Vue.prototype.$baseUrl = process.env.VUE_APP_BASE_URL
// Vue.prototype.$baseUrl = "http://81.68.198.224:8021/"
Vue.use(VueRouter);
Vue.use(ElementUI);
Vue.prototype.$message = function (msg) {
  //根据msg对象中的type类型判断消息提示的类型
  let msgObj = {
    message: msg.message ? msg.message : msg,
    duration: 1000
  }
  let msgType = msg.type || ""
  switch (msgType) {
    case 'success':
      return Message.success(msgObj);
      break;
    case 'warning':
      return Message.warning(msgObj);
      break;
    case 'error':
      return Message.error(msgObj);
      break;
    default:
      return Message(msgObj);
  }
}
//分别对success、warning和error等样式进行设置
Vue.prototype.$message.success = function (msg) {
  return Message.success({
    message: msg,
    duration: 1000
  })
}
Vue.prototype.$message.warning = function (msg) {
  return Message.warning({
    message: msg,
    duration: 1000
  })
}
Vue.prototype.$message.error = function (msg) {
  return Message.error({
    message: msg,
    duration: 1000
  })
}
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
