export default {
     change(bossDiscountTotal, collectionNum, costNum, userNum) {
          // let numberList = [20, 40, 50, 70, 100, 150, 200, 500];
          // let yearList = [2, 4, 6];

          // for (let i = 0; i < numberList.length; i++) {
          //     for (let j = 0; j < yearList.length; j++) {
          //         // this.getConsult2(yearList[j], numberList[i]);
          //     }
          // }

          // let arrSale100 = [
          //     { min: 0, max: 5, value: 1 },
          //     { min: 5, max: 10, value: 2 },
          // ];

          // let arrRale100 = [
          //     { value: 1, raleMin: 95, raleMax: 100, rale: 100 },
          //     { value: 1, raleMin: 90, raleMax: 94, rale: 95 },
          // ];

          let arr0 = [
               [100, 95, 93, 91, 89, 87, 0, 0, 0],
               [0, 90, 87.5, 85, 82.5, 80, 0, 0, 0],
               [0, 0, 85, 82, 79, 76, 0, 0, 0],
               [0, 0, 0, 80, 76.5, 73, 0, 0, 0],
          ],
               arr100 = [
                    [100, 97, 95, 93, 91, 89, 87, 85, 0],
                    [0, 95, 92.5, 90, 87.5, 85, 82.5, 80, 0],
                    [0, 0, 92, 89, 86, 83, 80, 77, 0],
                    [0, 0, 0, 90, 87, 83, 80, 76, 0],
               ],
               arr200 = [
                    [100, 100, 97, 95, 93, 91, 89, 87, 85],
                    [0, 100, 95, 92.5, 90, 87.5, 85, 82.5, 80],
                    [0, 0, 92, 89, 86, 83, 80, 77, 74],
                    [0, 0, 0, 90, 86.5, 83, 79.5, 76, 72.5],
               ];
          //销售费用比
          let costRatio =
               (costNum / bossDiscountTotal) * 100;
          // 折扣率
          let costDiscount =
               ((collectionNum - costNum) /
                    bossDiscountTotal) *
               100;
          // 比例
          let proportion = 0, isShow = false, achievement = 0;

          if (costDiscount < 55) {
               isShow = true;
          } else {
               isShow = false;
               if (userNum < 100) {
                    proportion = this.countProportion(
                         costRatio,
                         costDiscount,
                         arr0
                    );
               } else if (userNum >= 100 && userNum <= 200) {
                    proportion = this.countProportion(
                         costRatio,
                         costDiscount,
                         arr100
                    );
               } else if (userNum > 200) {
                    proportion = this.countProportion(
                         costRatio,
                         costDiscount,
                         arr200
                    );
               }
               let bossDiscountNum = Number(
                    bossDiscountTotal * 0.4
               ).toFixed(2);
               if (collectionNum > bossDiscountTotal) {
                    if (costNum == 0) {
                         achievement = collectionNum - bossDiscountNum;
                    } else {
                         if (
                              costNum <=
                              (collectionNum -
                                   bossDiscountTotal * 0.95) *
                              0.6
                         ) {
                              achievement =
                                   collectionNum - bossDiscountNum;
                              proportion = 100
                              isShow = false;
                         } else {
                              isShow = true
                         }
                    }
               } else {
                    if (costNum === '' || Number(costNum) === 0) {
                         // console.log(123);
                         achievement = collectionNum - bossDiscountNum;
                         proportion = 100
                    } else {
                         if (!proportion) {
                              isShow = true;
                         } else {
                              // console.log(proportion);
                              achievement =
                                   ((collectionNum - bossDiscountNum) *
                                        proportion) /
                                   100;
                         }
                    }
               }
          }
          return {
               isShow,
               achievement,
               proportion
          }
     },
     countProportion(costRatio, costDiscount, arr) {
          var proportion = 0;
          var newArr = [];
          if (costRatio <= 5) {
               newArr = arr[0];
          } else if (costRatio > 5 && costRatio <= 10) {
               newArr = arr[1];
          } else if (costRatio > 10 && costRatio <= 15) {
               newArr = arr[2];
          } else if (costRatio > 15 && costRatio <= 20) {
               newArr = arr[3];
          }
          if (costDiscount >= 95) {
               proportion = newArr[0];
          } else if (costDiscount >= 90 && costDiscount < 95) {
               proportion = newArr[1];
          } else if (costDiscount >= 85 && costDiscount < 90) {
               proportion = newArr[2];
          } else if (costDiscount >= 80 && costDiscount < 85) {
               proportion = newArr[3];
          } else if (costDiscount >= 75 && costDiscount < 80) {
               proportion = newArr[4];
          } else if (costDiscount >= 70 && costDiscount < 75) {
               proportion = newArr[5];
          } else if (costDiscount >= 65 && costDiscount < 70) {
               proportion = newArr[6];
          } else if (costDiscount >= 60 && costDiscount < 65) {
               proportion = newArr[7];
          } else if (costDiscount >= 55 && costDiscount < 60) {
               proportion = newArr[8];
          }
          // if (proportion == 0) {
          //     proportion = 1;
          // }
          return proportion;
     },
     calculator(bossDiscountTotal, collectionNum, costNum, proportion) {
          console.log(bossDiscountTotal, collectionNum, costNum, proportion);
          // let numberList = [20, 40, 50, 70, 100, 150, 200, 500];
          // let yearList = [2, 4, 6];

          // for (let i = 0; i < numberList.length; i++) {
          //     for (let j = 0; j < yearList.length; j++) {
          //         // this.getConsult2(yearList[j], numberList[i]);
          //     }
          // }

          // let arrSale100 = [
          //     { min: 0, max: 5, value: 1 },
          //     { min: 5, max: 10, value: 2 },
          // ];

          // let arrRale100 = [
          //     { value: 1, raleMin: 95, raleMax: 100, rale: 100 },
          //     { value: 1, raleMin: 90, raleMax: 94, rale: 95 },
          // ];

          // let arr0 = [
          //      [100, 95, 93, 91, 89, 87, 0, 0, 0],
          //      [0, 90, 87.5, 85, 82.5, 80, 0, 0, 0],
          //      [0, 0, 85, 82, 79, 76, 0, 0, 0],
          //      [0, 0, 0, 80, 76.5, 73, 0, 0, 0],
          // ],
          //      arr100 = [
          //           [100, 97, 95, 93, 91, 89, 87, 85, 0],
          //           [0, 95, 92.5, 90, 87.5, 85, 82.5, 80, 0],
          //           [0, 0, 92, 89, 86, 83, 80, 77, 0],
          //           [0, 0, 0, 90, 87, 83, 80, 76, 0],
          //      ],
          //      arr200 = [
          //           [100, 100, 97, 95, 93, 91, 89, 87, 85],
          //           [0, 100, 95, 92.5, 90, 87.5, 85, 82.5, 80],
          //           [0, 0, 92, 89, 86, 83, 80, 77, 74],
          //           [0, 0, 0, 90, 86.5, 83, 79.5, 76, 72.5],
          //      ];
          //销售费用比
          let costRatio =
               (costNum / bossDiscountTotal) * 100;
          // 折扣率
          let costDiscount =
               ((collectionNum - costNum) /
                    bossDiscountTotal) *
               100;
          // 比例
          let isShow = false, achievement = 0;

          if (costDiscount < 55) {
               isShow = true;
          } else {
               isShow = false;
               let bossDiscountNum = Number(
                    bossDiscountTotal * 0.4
               ).toFixed(2);
               achievement =
                    ((collectionNum - bossDiscountNum) *
                         proportion) /
                    100;

          }
          return {
               isShow,
               achievement
          }
     },
}