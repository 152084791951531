export default {
  treeDataTranslate(data, id = 'id', pid = 'parentId', tag = true) {
    // // console.log(data[1])
    var res = [];
    var temp = {};
    for (var i = 0; i < data.length; i++) {
      if ((data[i].parentId == 0 || Number(data[i].type) == 2) && tag) {
        continue;
      }
      temp[data[i][id]] = data[i];
      if (i == 1) {
        // // console.log(data[i])
      }
    }
    // // console.log(temp)
    for (var k = 0; k < data.length; k++) {
      if ((data[k].parentId == 0 || Number(data[k].type) == 2) && tag) {
        continue;
      }
      if (temp[data[k][pid]] && data[k][id] !== data[k][pid]) {
        if (!temp[data[k][pid]]['children']) {
          temp[data[k][pid]]['children'] = [];
        }
        if (!temp[data[k][pid]]['_level']) {
          temp[data[k][pid]]['_level'] = 1;
        }
        data[k]['_level'] = temp[data[k][pid]]._level + 1;
        temp[data[k][pid]]['children'].push(data[k]);
      } else {
        res.push(data[k]);
      }
    }
    // // console.log(res[1]);
    return res;

  },

  treeDataTranslateAudit(data, id = 'id', pid = 'parentId') {
    var res = [];
    var temp = {};
    for (var i = 0; i < data.length; i++) {
      temp[data[i][id]] = data[i];
    }
    for (var k = 0; k < data.length; k++) {
      if (temp[data[k][pid]] && String(data[k][id]) !== data[k][pid]) {
        if (!temp[data[k][pid]]['children']) {
          temp[data[k][pid]]['children'] = [];
        }
        if (!temp[data[k][pid]]['_level']) {
          temp[data[k][pid]]['_level'] = 1;
        }
        data[k]['_level'] = temp[data[k][pid]]._level + 1;
        if (temp[data[k][pid]]['children'].indexOf(data[k]) == -1) {
          temp[data[k][pid]]['children'].push(data[k]);
        }
      } else {
        res.push(data[k]);
      }
    }
    return res;
  },

  childrenData(parentData) {
    let menuList = parentData;
    let childrens = [];
    menuList.forEach((ml) => {
      if (Number(ml.type) == 2) {
        childrens.push(ml);
      }
    });
    return childrens;
  },
  convert(list) {
    const res = [];
    const map = list.reduce((res, v) => ((res[v.departmentId] = v), res), {});
    for (const item of list) {
      if (item.parentId == 0) {
        res.push(item);
        continue;
      }
      if (item.parentId in map) {
        const parent = map[item.parentId];

        parent.children = parent.children || [];
        parent.children.push(item);
      }
    }
    return res;
  },
  handleTree(data) {
    data.forEach(function (item) {
      delete item.children;
    });

    // 将数据存储为 以 id 为 KEY 的 map 索引数据列
    var map = {};
    data.forEach(function (item) {
      map[item.departmentId] = item;
    });
    var val = [];
    data.forEach(function (item) {
      // 以当前遍历项的pid,去map对象中找到索引的id
      var parent = map[item.parentId];
      // 如果找到索引，那么说明此项不在顶级当中,那么需要把此项添加到，他对应的父级中
      if (parent) {
        (parent.children || (parent.children = [])).push(item);
      } else {
        //如果没有在map中找到对应的索引ID,那么直接把 当前的item添加到 val结果集中，作为顶级
        val.push(item);
      }
    });
    return val;
  },
  EditTree(data) {
    data.forEach(function (item) {
      delete item.children;
    });

    // 将数据存储为 以 id 为 KEY 的 map 索引数据列
    var map = {};
    data.forEach(function (item) {
      map[item.id] = item;
    });

    var val = [];
    data.forEach(function (item) {
      // 以当前遍历项的pid,去map对象中找到索引的id
      var parent = map[item.parentId];
      // 如果找到索引，那么说明此项不在顶级当中,那么需要把此项添加到，他对应的父级中
      if (parent) {
        (parent.children || (parent.children = [])).push(item);
      } else {
        //如果没有在map中找到对应的索引ID,那么直接把 当前的item添加到 val结果集中，作为顶级
        val.push(item);
      }
    });
    return val;
  },
  unique(arr, u_key) {
    let obj = {};
    let result = [];
    arr.forEach((item) => {
      let typeof_key = typeof item[u_key] + item[u_key];
      obj[typeof_key] = item;
    });
    for (let key in obj) {
      result.push(obj[key]);
    }
    return result;
  },
};
