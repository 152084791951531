export default {

     Merchant(val) {
          if (val == '1486712492') {
               return '浙江伽搜科技有限公司'
          }
          if (val == '1604268441') {
               return '浙江伽搜科技有限公司'
          }
          if (val == '1608520489') {
               return '杭州伽湾信息技术有限公司'
          }
          return '- -'
     }

}