import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    intercourseList: [],
    i: -1,
    dealingsId: '',
    labelList: []
  },
  //状态管理
  mutations: {
    //添加往来
    addDealings(state, params) {
      let data = JSON.parse(params)
      data.describeList = []
      state.intercourseList.unshift(data)
    },
    // 往来列表
    DealingsList(state, params) {
      state.intercourseList = JSON.parse(params)
    },
    // 获取下标
    getIndex(state, params) {
      state.i = params
    },
    getDealingsId(state, params) {
      state.dealingsId = params
      // console.log(params);
    },
    // 添加说明
    addExplain(state, data) {
      state.intercourseList[state.i].describeList.push(JSON.parse(data))
    },
    // 清空数据
    emptyData(state) {
      state.intercourseList = []
      state.i = -1
      state.dealingsId = ''
    }
  },   //修改state
  actions: {
  },    //异步操作
  modules: {}     //模块
})
export default store
