// js获取本周、本月时间
var now = new Date(); // 当前日期
var nowDayOfWeek = now.getDay(); // 今天本周的第几天
var nowDay = now.getDate(); // 当前日
var nowMonth = now.getMonth(); // 当前月
var nowYear = now.getYear(); // 当前年
nowYear += (nowYear < 2000) ? 1900 : 0;

var DateUtil = {
    /**
     * 获得当前日期
     *
     * @returns
     */
    getNowDay() {
        return this.formatDate(new Date());
    },
    /**
     * 获得本周的开始时间
     *
     * @returns
     */
    getStartDayOfWeek() {
        var day = nowDayOfWeek || 7;
        return this.formatDate(new Date(now.getFullYear(), nowMonth, nowDay + 1 - day));
    },
    /**
     * 获得本周的结束时间
     *
     * @returns
     */
    getEndDayOfWeek() {
        var day = nowDayOfWeek || 7;
        return this.formatDate(new Date(now.getFullYear(), nowMonth, nowDay + 7 - day));
    },
    /**
     * 获得本月的开始时间
     *
     * @returns
     */
    getStartDayOfMonth() {
        var monthStartDate = new Date(nowYear, nowMonth, 1);
        return this.formatDate(monthStartDate);
    },
    /**
     * 获得本月的结束时间
     *
     * @returns
     */
    getEndDayOfMonth() {
        var monthEndDate = new Date(nowYear, nowMonth, this.getMonthDays());
        return this.formatDate(monthEndDate);
    },
    /**
     * 获得本月天数
     *
     * @returns
     */
    getMonthDays() {
        var monthStartDate = new Date(nowYear, nowMonth, 1);
        var monthEndDate = new Date(nowYear, nowMonth + 1, 1);
        var days = (monthEndDate - monthStartDate) / (1000 * 60 * 60 * 24);
        return days;
    },
    /**
     * @param 日期格式化
     * @returns {String}
     */
    formatDate(date) {
        var myyear = date.getFullYear();
        var mymonth = date.getMonth() + 1;
        var myweekday = date.getDate();

        if (mymonth < 10) {
            mymonth = "0" + mymonth;
        }
        if (myweekday < 10) {
            myweekday = "0" + myweekday;
        }
        return (myyear + "-" + mymonth + "-" + myweekday);
    }
};




export default {
    DateUtil,
    getStartDay() {
        var monthStartDate = new Date(nowYear, nowMonth, 1);
        var myyear = monthStartDate.getFullYear();
        var mymonth = monthStartDate.getMonth() + 1;
        var myweekday = monthStartDate.getDate();

        if (mymonth < 10) {
            mymonth = "0" + mymonth;
        }
        if (myweekday < 10) {
            myweekday = "0" + myweekday;
        }
        return (myyear + "-" + mymonth + "-" + myweekday);
    },
    getEndDayOfMonth() {
        var monthEndDate = new Date(nowYear, nowMonth, this.getMonthDays());
        var myyear = monthEndDate.getFullYear();
        var mymonth = monthEndDate.getMonth() + 1;
        var myweekday = monthEndDate.getDate();

        if (mymonth < 10) {
            mymonth = "0" + mymonth;
        }
        if (myweekday < 10) {
            myweekday = "0" + myweekday;
        }
        return (myyear + "-" + mymonth + "-" + myweekday);
    },
    getNextDate(date, day) {
        var dd = new Date(date);
        dd.setDate(dd.getDate() - Number(day));
        var y = dd.getFullYear();
        var m = dd.getMonth() + 1 < 10 ? '0' + (dd.getMonth() + 1) : dd.getMonth() + 1;
        var d = dd.getDate() < 10 ? '0' + dd.getDate() : dd.getDate();
        return y + '-' + m + '-' + d;
    },
    thisMonth() {
        Date.prototype.format = function (fmt) {
            var o = {
                'M+': this.getMonth() + 1, //月份
                'd+': this.getDate(), //日
                'h+': this.getHours(), //小时
                'm+': this.getMinutes(), //分
                's+': this.getSeconds(), //秒
                'q+': Math.floor((this.getMonth() + 3) / 3), //季度
                S: this.getMilliseconds(), //毫秒
            };
            if (/(y+)/.test(fmt)) {
                fmt = fmt.replace(
                    RegExp.$1,
                    (this.getFullYear() + '').substr(4 - RegExp.$1.length),
                );
            }
            for (var k in o) {
                if (new RegExp('(' + k + ')').test(fmt)) {
                    fmt = fmt.replace(
                        RegExp.$1,
                        RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length),
                    );
                }
            }
            return fmt;
        };
        var date = new Date();
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        return [firstDay.format('yyyy-MM-dd'), date.format('yyyy-MM-dd')];
    },
    getLastMonthAndDay() {
        let nowDate = new Date();
        let year = nowDate.getFullYear();
        let month = nowDate.getMonth();
        if (month == 0) {
            month = 12;
            year = year - 1;
        }
        let lastDay = new Date(year, month, 1);
        let yyyyMMdd = year + "-" + month + "-" + lastDay.getDate();
        return yyyyMMdd;
    },
    getDay() {
        ;


    },
    // 获取3个月后的时间
    getThreeMothTime(mon) {
        var now = new Date(); //当前日期
        var Year = now.getFullYear();
        var Month = now.getMonth() + 1;
        var Date2 = now.getDate();
        //试用期截至
        var mydate = new Date(Year, Month + mon - 1, Date2);
        var Yeara = mydate.getFullYear();
        var Monthb = mydate.getMonth() + 1;
        var Datec = mydate.getDate();
        if (Monthb < 10) {
            Monthb = '0' + Monthb
        }
        if (Datec < 10) {
            Datec = '0' + Datec
        }
        return Yeara + '-' + Monthb + '-' + Datec
    },
    getMonthStartDate() {
        var now = new Date(); //当前日期
        var nowDay = now.getDate(); //当前日
        var nowMonth = now.getMonth(); //当前月
        var nowYear = now.getYear(); //当前年
        //// console.log(nowMonth);
        nowYear += (nowYear < 2000) ? 1900 : 0; //
        var lastMonthDate = new Date(); //上月日期
        // // console.log('lastMonthDate'+lastMonthDate)
        lastMonthDate.setDate(1);   //变成这个月一号
        lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);
        var lastYear = lastMonthDate.getYear();
        lastYear += (lastYear < 2000) ? 1900 : 0; //
        // // console.log('lastYear'+lastYear);
        var lastMonth = lastMonthDate.getMonth()
        var lastMonthStartDate = new Date(lastYear, lastMonth, 1);
        return this.formatDate(lastMonthStartDate);
    },
    getLastMonthEndDate() {
        var now = new Date(); //当前日期
        var nowDay = now.getDate(); //当前日
        var nowMonth = now.getMonth(); //当前月
        var nowYear = now.getYear(); //当前年
        //// console.log(nowMonth);
        nowYear += (nowYear < 2000) ? 1900 : 0; //
        var lastMonthDate = new Date(); //上月日期
        // // console.log('lastMonthDate'+lastMonthDate)
        lastMonthDate.setDate(1);   //变成这个月一号
        lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);
        var lastYear = lastMonthDate.getYear();
        lastYear += (lastYear < 2000) ? 1900 : 0; //
        // // console.log('lastYear'+lastYear);
        var lastMonth = lastMonthDate.getMonth()
        var lastMonthEndDate = new Date(lastYear, lastMonth, this.getMonthDays(lastMonth));
        return this.formatDate(lastMonthEndDate);
    },
    getMonthDays(myMonth) {
        var now = new Date(); //当前日期
        var nowDay = now.getDate(); //当前日
        var nowMonth = now.getMonth(); //当前月
        var nowYear = now.getYear(); //当前年
        //// console.log(nowMonth);
        nowYear += (nowYear < 2000) ? 1900 : 0; //
        var lastMonthDate = new Date(); //上月日期
        // // console.log('lastMonthDate'+lastMonthDate)
        lastMonthDate.setDate(1);   //变成这个月一号
        lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);
        var lastYear = lastMonthDate.getYear();
        lastYear += (lastYear < 2000) ? 1900 : 0; //
        // // console.log('lastYear'+lastYear);
        var monthStartDate = new Date(nowYear, myMonth, 1);
        var monthEndDate = new Date(nowYear, myMonth + 1, 1);
        //   // console.log('天数'+monthStartDate+'==='+ monthEndDate)
        var days = (monthEndDate - monthStartDate) / (1000 * 60 * 60 * 24);
        //  // console.log('天数，数量：'+days);
        return days;
    },
    formatDate(date) {
        var myyear = date.getFullYear();
        var mymonth = date.getMonth() + 1;
        var myweekday = date.getDate();

        if (mymonth < 10) {
            mymonth = "0" + mymonth;
        }
        if (myweekday < 10) {
            myweekday = "0" + myweekday;
        }
        return (myyear + "-" + mymonth + "-" + myweekday);
    },
}