export default {




     declareType() {
          let json = [
               // {
               //      label: "现金",
               //      value: 1
               // },
               // {
               //      label: "礼品",
               //      value: 2
               // },
               {
                    label: "招待费",
                    value: 3
               },
               {
                    label: "商务费",
                    value: 5
               },
               {
                    label: "其他",
                    value: 4
               },
          ]
          return json
     },
     // 申报明细类型
     declareDetailType(type) {
          if (type == 1) {
               return "现金"
          } else if (type == 2) {
               return "礼品"
          } else if (type == 3) {
               return "招待费"
          } else if (type == 4) {
               return "其他"
          } else if (type == 5) {
               return "商务费"
          } else {
               return "- -"
          }
     },
     // 销售费用状态
     costStatus(status) {
          if (status == 1 || status == 2 || status == 3) {
               return "待审批"
          } else if (status == 8 || status == 7 || status == 6) {
               return "已驳回"
          } else if (status == 9) {
               return "已撤销"
          } else if (status == 5) {
               return "已关联"
          } else if (status == 4) {
               return "待支付"
          } else if (status == 10) {
               return "重新编辑"
          } else if (status == 11) {
               return "已支付"
          } else if (status == 12) {
               return "已作废"
          } else if (status == 13) {
               return "支付中"
          } else {
               return '- -'
          }
     },
     reimbType() {
          let json = [
               {
                    label: "差旅",
                    value: 1
               },
               {
                    label: "招待费",
                    value: 2
               },
               {
                    label: "商务费",
                    value: 3
               },

               {
                    label: "其他",
                    value: 4
               },
          ]
          return json
     },
     // 报销状态
     reimbStatus(status) {
          if (status == 1 || status == 2 || status == 3) {
               return "待审批"
          } else if (status == 8 || status == 7 || status == 6) {
               return "已驳回"
          } else if (status == 9) {
               return "已撤销"
          } else if (status == 5) {
               return "已关联"
          } else if (status == 4) {
               return "已通过"
          } else if (status == 10) {
               return "重新编辑"
          } else if (status == 11) {
               return "已支付"
          } else if (status == 12) {
               return "已作废"
          } else {
               return '- -'
          }
     },
     // 报销类型
     expenseType(status) {
          if (status == 1) {
               return "差旅"
          } else if (status == 2) {
               return "招待费"
          } else if (status == 3) {
               return "商务费"
          } else if (status == 4) {
               return "其他"
          } else {
               return '- -'
          }
     },
     // 业绩状态
     performanceStatus(status) {
          if (status == 1) {
               return "待到款"
          } else if (status == 2) {
               return "待确认"
          } else if (status == 3) {
               return "已确认"
          } else if (status == 4) {
               return "已退款"
          } else if (status == 5) {
               return "已到期"
          } else {
               return '- -'
          }
     }
}