import Vue from "vue"
let vm = new Vue()


export default {
    url() {
        return vm.$baseUrl + 'jasoboss';
    },
    downUrl() {
        return vm.$baseUrl + 'contract/model/'
    },
    templateUrl() {
        return process.env.VUE_APP_BASE_TEMPLATER_URL;
        // return 'http://www.ijasoso.com';
    },
    xlsxUrl() {
        return process.env.VUE_APP_BASE_XLSX_URL;
        // return 'http://www.ijasoso.com/excel/';
    },
};
