export default {
    targetList() {
        let json = [
            {
                label: "最近常用",
                value: 'zjcy',
                children: JSON.parse(sessionStorage.getItem('often')) ? JSON.parse(sessionStorage.getItem('often')) : []
            },
            {
                label: "企业邮箱",
                value: 'qyyx',
                children: [
                    {
                        label: "腾讯企业邮箱",
                        value: 1
                    },
                    {
                        label: "阿里企业邮箱",
                        value: 2
                    },
                    {
                        label: "网易企业邮箱",
                        value: 4
                    },
                ]
            },
            {
                label: "企业网站",
                value: 'qywz',
                children: [
                    {
                        label: "域名",
                        value: 12
                    },
                    {
                        label: "虚拟主机",
                        value: 8
                    },
                    {
                        label: "网站",
                        value: 5
                    },
                ]
            },
            {
                label: "企业微信",
                value: 'qywx',
                children: [
                    {
                        label: "微盘",
                        value: 7
                    },
                    {
                        label: "会话存档接口",
                        value: 10
                    },
                    {
                        label: "会话存档系统",
                        value: 14
                    },
                ]
            },
            {
                label: "其他产品",
                value: 'qtcp',
                children: [
                    {
                        label: "云产品",
                        value: 3
                    },
                    {
                        label: "投屏盒子",
                        value: 6
                    },
                    {
                        label: "服务器",
                        value: 9
                    },
                    {
                        label: "飞连",
                        value: 13
                    },{
                        label: "企业微信",
                        value: 11
                    }
                ]
            },
        ]
        return json
    },
}