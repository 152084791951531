import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        isDetails: false, //是否为详情页面
        type: '', //标签类型
        bindingType: '',//绑定类型
        labelList: [], //标签集合
        isMultiple: false,//是否多个
        MaillabelList: [],//多个联系人标签集合
    },
    //状态管理
    mutations: {
        // 是否为详情页面
        getIsDetails(state, params) {
            state.isDetails = params
        },
        // 获取标签集合
        getLabelList(state, params) {

            state.labelList = JSON.parse(params)

        },
        // 获取标签类型
        getType(state, params) {
            state.type = params
        },
        // 获取标签绑定类型
        getBindingType(state, params) {
            state.bindingType = params
        },
        // 移除标签
        removeLabel(state, params) {
            let data = JSON.parse(params)
            for (let i in state.labelList) {
                if (state.labelList[i].id == data.id) {
                    state.labelList.splice(i, 1)
                }
            }
        },
        // 获取是否多个
        isMultiple(state, params) {
            state.isMultiple = params
        },
        // 联系人数组标签
        choiceMailLabel(state, params) {
            // console.log();
            state.MaillabelList[JSON.parse(params).index] = JSON.parse(params).arr
            // console.log(state.MaillabelList);
        },
        removeMailLabel(state, params) {
            state.MaillabelList[params[0]].splice(params[1], 1)
        },
        replaceState(state, params) {
            state.data = params.data
        },
        // 清空
        emptyData(state) {
            state.isDetails = false
            state.isMultiple = false
            state.type = ''
            state.labelList = []
            state.MaillabelList = []
        },
    },
    //修改state
    actions: {
    },
})
export default store
