import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        data: {},
    },       //状态管理
    mutations: {
        //添加往来
        mailDetails(state, params) {
            state.data = JSON.parse(params)
        },
        replaceState(state, params) {
            state.data= params.data
        },
        // 清空数据
        emptyData(state) {
            state.data = {}
            state.intercourseList = []
            state.labelList =[]
        }
    },   //修改state
    actions: {
    },    //异步操作
    modules: {}     //模块
})
export default store
