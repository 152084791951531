import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        data: {},
    },
    //状态管理
    mutations: {
        //详情数据
        detailsData(state, params) {
            // console.log(JSON.parse(params),'详情');
            state.data = JSON.parse(params)
        },
        replaceState(state, params) {
            state.data = params.data
        },
        // 清空数据
        emptyData(state) {
            // state.data = {}
        }
    },
})
export default store
