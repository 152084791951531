<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: 'App',
    data() {
        return {
            screenWidth: document.body.clientWidth,
        };
    },
    mounted() {
        const that = this;
        window.onresize = () => {
            return (() => {
                window.screenWidth = document.body.clientWidth;
                that.screenWidth = window.screenWidth;
            })();
        };
    },

    watch: {
        /* 监听*/
        screenWidth(val) {
            this.screenWidth = val;
            // console.log('this.screenWidth', this.screenWidth);
        },
    },
    created() {
        //在页面加载时读取sessionStorage里的状态信息
        if (sessionStorage.getItem('clueDetails')) {
            this.$clueDetails.replaceState(
                Object.assign(
                    {},
                    this.$clueDetails.state,
                    JSON.parse(sessionStorage.getItem('clueDetails'))
                )
            );
        }
        if (sessionStorage.getItem('maildetails')) {
            this.$maildetails.replaceState(
                Object.assign(
                    {},
                    this.$maildetails.state,
                    JSON.parse(sessionStorage.getItem('maildetails'))
                )
            );
        }
        if (sessionStorage.getItem('formdetails')) {
            this.$formDetails.replaceState(
                Object.assign(
                    {},
                    this.$formDetails.state,
                    JSON.parse(sessionStorage.getItem('formdetails'))
                )
            );
        }
        if (sessionStorage.getItem('choiceLabel')) {
            this.$choiceLabel.replaceState(
                Object.assign(
                    {},
                    this.$choiceLabel.state,
                    JSON.parse(sessionStorage.getItem('choiceLabel'))
                )
            );
        }
        if (sessionStorage.getItem('database')) {
            this.$database.replaceState(
                Object.assign(
                    {},
                    this.$database.state,
                    JSON.parse(sessionStorage.getItem('database'))
                )
            );
        }
        //在页面刷新时将vuex里的信息保存到sessionStorage里
        window.addEventListener('beforeunload', () => {
            sessionStorage.setItem(
                'clueDetails',
                JSON.stringify(this.$clueDetails.state)
            );
            sessionStorage.setItem(
                'maildetails',
                JSON.stringify(this.$maildetails.state)
            );
            sessionStorage.setItem(
                'formdetails',
                JSON.stringify(this.$formDetails.state)
            );
            sessionStorage.setItem(
                'choiceLabel',
                JSON.stringify(this.$choiceLabel.state)
            );
            sessionStorage.setItem(
                'database',
                JSON.stringify(this.$database.state)
            );
        });
    },
};
</script>

<style lang="scss">
.el-form-item {
    margin-bottom: 16px !important;
}
* {
    padding: 0;
    margin: 0;
}
html,
body {
    width: 100%;
    height: 100%;
    font-family: PingFangSC-Regular, PingFang SC;
    margin: 0 auto;
    background: #fff;
    position: relative;
    overflow: auto;
    min-width: 1360px;
    min-height: 750px;
    max-width: 2440px;
}
@media screen and(max-width: 750px) {
    html,
    body {
        min-width: 100%;
        min-height: 100%;
    }
}
#app {
    width: 100%;
    height: 100%;
}
// .el-table__fixed-right,
// .el-table__fixed {
//     height: auto !important;
//     bottom: 12px;
// }
.el-table__fixed {
    bottom: 0;
}
.el-table__fixed-body-wrapper .el-table__body {
    padding-bottom: 10px;
}
::-webkit-scrollbar {
    background-color: rgba(27, 27, 27, 0.4);
    border-radius: 10px;
    width: 3px;
    height: 10px;
}
.el-table .cell {
    text-overflow: initial !important;
    padding-right: 0 !important;
}
::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: rgba(117, 117, 117, 0.4);
}

::-webkit-scrollbar-track {
    border-radius: 10px;
    background: #fff;
}
/* 分页公用样式 */
.el-pagination {
    .btn-prev,
    .btn-next {
        border: 1px solid rgba(0, 0, 0, 0.15);
        padding: 0 !important;
        margin: 0 8px !important;
    }

    .number {
        border: 1px solid rgba(0, 0, 0, 0.15);
        padding: 0;
    }
    .number.active {
        border-color: #1890ff;
    }
}
// 单选框
.el-radio-button__orig-radio:checked + .el-radio-button__inner {
    background: #fff !important;
    color: #2162cb !important;
    border-color: #2162cb !important;
    box-shadow: none !important;
    border-left: 1px solid #2162cb;
}
.el-radio-button__inner:hover {
    color: #2162cb !important;
}
.el-radio-button--small {
    box-shadow: none !important;
    /*去掉点击的时候带的阴影边框*/
}
.el-input__inner {
    border-color: #e5e5e5 !important;
}
.el-form-item__label {
    font-size: 14px !important;
    font-weight: 600 !important;
    color: #333333 !important;
}

// 弹窗
.el-dialog__header {
    background: #f5f6f7;
}
.el-dialog__body {
    padding: 0 !important;
}
// 抽屉
#el-drawer__title {
    padding: 16px 24px;
    margin: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 24px;
}
.el-collapse-item__header {
    height: 60px !important;
}
.table-box .el-button {
    padding: 0 !important;
}
.el-table th.el-table__cell > .cell {
    font-weight: bold;
}
.el-form-item__error {
    left: 10px !important;
}
.el-tooltip__popper {
    max-width: 600px;
}
.el-collapse-item__content {
    padding: 0 !important;
}
.add-but {
    padding: 0 !important;
}
.choice {
    padding-bottom: 12px;
    display: flex;
    align-items: flex-start;
    .labelList {
        display: inline-block;
        width: 80%;
        .cancel {
            position: absolute;
            display: block;
            width: 0;
            height: 0;
            color: #fff;
            border-top: 10px solid rgba(255, 255, 255, 0);
            border-right: 10px solid rgba(255, 255, 255, 0);
            border-bottom: 10px solid rgba(255, 255, 255, 0);
            border-left: 10px solid yellow;
            -webkit-transform: rotate(45deg); /* for Chrome || Safari */
            -moz-transform: rotate(45deg); /* for Firefox */
            -ms-transform: rotate(45deg); /* for IE */
            -o-transform: rotate(45deg);
            right: -26px;
            bottom: -10px;
            .el-icon-close {
                color: #fff;
                position: absolute;
                font-size: 8px;
                left: -28px;
                top: -6px;
                -webkit-transform: rotate(45deg); /* for Chrome || Safari */
                -moz-transform: rotate(45deg); /* for Firefox */
                -ms-transform: rotate(45deg); /* for IE */
                -o-transform: rotate(45deg);
                cursor: pointer;
            }
        }
        p {
            position: relative;
            display: inline-block;
            padding: 0 10px;
            line-height: 30px;
            border-radius: 2px;
            border: 1px solid #2370eb;
            margin-left: 16px;
            text-align: center;
            font-size: 14px;
        }
        .blue {
            color: #2370eb;
            border-color: #2370eb;
            .cancel {
                border-left: 10px solid #2370eb;
            }
        }
        .yellow {
            color: #fa8c15;
            border-color: #fa8c15;
            .cancel {
                border-left: 10px solid #fa8c15;
            }
        }
    }
}
.el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
    margin-left: -9px;
}
.icon-dianhua,
.icon-weixin,
.icon-QQ,
.icon-youxiang,
.icon-qita,
.icon-youxiang1,
.icon-yuyuebaifang,
.icon-qita1,
.icon-diannao,
.icon-duanxinxiaoxixinxi,
.icon-zuoji {
    position: absolute;
    left: -10px;
    top: 49%;
    transform: translateY(-50%);
}
.el-input.is-disabled .el-input__inner {
    border: none;
    color: #333 !important;
    background-color: #f9f9f9 !important;
}
.el-input.is-disabled span {
    display: none;
}
.el-textarea.is-disabled .el-textarea__inner {
    border: none;
    color: #333 !important;
    background-color: #f9f9f9 !important;
}
.el-upload-list--picture-card .el-upload-list__item {
    width: 122px !important;
    height: 122px !important;
}
.el-table td {
    white-space: nowrap !important;
    text-overflow: initial !important;
}
.el-table .cell {
    white-space: nowrap !important;
    text-overflow: initial !important;
}
.el-loading-spinner .el-icon-loading {
    color: #fff !important;
    font-size: 20px !important;
}
.el-loading-text {
    color: #fff !important;
    font-size: 20px !important;
}
.el-table th {
    font-size: 13px !important;
    font-weight: 550 !important;
    font-family: PingFangSC-Medium, PingFang SC !important;
}
table thead tr th {
    font-weight: 600 !important;
}
.search-box > div > span,
.search-box > div > p > span {
    font-weight: 600 !important;
}
.search-box {
    margin-bottom: 12px !important;
}
.el-input--small {
    font-size: 12px !important;
}
.el-table th.gutter {
    display: table-cell !important;
}
body .el-table--scrollable-y {
    // .el-table__body-wrapper::-webkit-scrollbar {
    //     width: 9px;
    //     height: 9px;
    // }
    .gutter {
        width: 10px !important;
    }
}
.el-popover {
    box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.27) !important;
    border-radius: 8px !important;
    padding: 10px 7px !important;
}
.monthStyle .el-date-picker__header--bordered {
    display: none !important;
}
</style>
