export default {
    primaryEmailBrand(row) {
        if (row.primaryEmailBrand == 1) {
            return '腾讯'
        } else if (row.primaryEmailBrand == 2) {
            return '阿里'
        } else if (row.primaryEmailBrand == 3) {
            return '网易'
        } else if (row.primaryEmailBrand == 4) {
            return '263'
        } else if (row.primaryEmailBrand == 5) {
            return '35'
        } else if (row.primaryEmailBrand == 6) {
            return '自建'
        } else if (row.primaryEmailBrand == 7) {
            return '其他'
        } else if (row.primaryEmailBrand == 8) {
            return '阿里云收费版'
        } else if (row.primaryEmailBrand == 35) {
            return '阿里云免费版'
        } else if (row.primaryEmailBrand == 9) {
            return '腾讯收费版'
        } else if (row.primaryEmailBrand == 10) {
            return '腾讯免费版'
        } else if (row.primaryEmailBrand == 11) {
            return '263'
        } else if (row.primaryEmailBrand == 12) {
            return '网易收费版'
        } else if (row.primaryEmailBrand == 13) {
            return '网易免费版'
        } else if (row.primaryEmailBrand == 14) {
            return '35互联'
        } else if (row.primaryEmailBrand == 15) {
            return '美橙互联'
        } else if (row.primaryEmailBrand == 16) {
            return '盈世'
        } else if (row.primaryEmailBrand == 17) {
            return '电信'
        } else if (row.primaryEmailBrand == 18) {
            return '中企动力'
        } else if (row.primaryEmailBrand == 19) {
            return '中资源'
        } else if (row.primaryEmailBrand == 20) {
            return '网盛生意宝'
        } else if (row.primaryEmailBrand == 21) {
            return '自建'
        } else if (row.primaryEmailBrand == 22) {
            return '爱思华宝'
        } else if (row.primaryEmailBrand == 23) {
            return '新网'
        } else if (row.primaryEmailBrand == 24) {
            return '磐邮'
        } else if (row.primaryEmailBrand == 25) {
            return 'FangMail'
        } else if (row.primaryEmailBrand == 26) {
            return 'U-Mail'
        } else if (row.primaryEmailBrand == 27) {
            return '新浪'
        } else if (row.primaryEmailBrand == 28) {
            return '雅虎'
        } else if (row.primaryEmailBrand == 29) {
            return 'Coremail'
        } else if (row.primaryEmailBrand == 30) {
            return '论客'
        } else if (row.primaryEmailBrand == 31) {
            return '微软/outlook'
        } else if (row.primaryEmailBrand == 32) {
            return '时代互联'
        } else if (row.primaryEmailBrand == 33) {
            return '云邮'
        } else if (row.primaryEmailBrand == 34) {
            return '无邮箱'
        } else {
            return '- -'
        }
    },
    clueSource(row) {
        if (row.clueSource == 1) {
            return '商桥-百度'
        } else if (row.clueSource == 2) {
            return '商桥-360'
        } else if (row.clueSource == 3) {
            return '商桥-搜狗'
        } else if (row.clueSource == 4) {
            return '商桥-非搜索引擎'
        } else if (row.clueSource == 5) {
            return '商桥-直接访问'
        } else if (row.clueSource == 6) {
            return '400电话'
        } else if (row.clueSource == 7) {
            return '官网表单'
        } else if (row.clueSource == 8) {
            return '邮件'
        } else if (row.clueSource == 9) {
            return '老客介绍'
        } else if (row.clueSource == 10) {
            return '自拓'
        } else if (row.clueSource == 11) {
            return '微信扫一扫'
        } else if (row.clueSource == 12) {
            return '在线留言'
        } else if (row.clueSource == 13) {
            return '其他'
        } else if (row.clueSource == 14) {
            return '华为云邮箱'
        } else if (row.clueSource == 15) {
            return '华为云建站'
        } else if (row.clueSource == 16) {
            return '华为云服务器'
        } else if (row.clueSource == 30) {
            return '伽搜-百度'
        } else if (row.clueSource == 31) {
            return '伽搜-360'
        } else if (row.clueSource == 32) {
            return '伽搜-搜狗'
        } else if (row.clueSource == 33) {
            return '伽搜-非搜索引擎'
        } else if (row.clueSource == 34) {
            return '伽搜-直接访问'
        } else if (row.clueSource == 35) {
            return '伽搜-百度'
        } else if (row.clueSource == 36) {
            return '伽搜-360'
        } else if (row.clueSource == 37) {
            return '伽搜-搜狗'
        } else if (row.clueSource == 38) {
            return '伽湾-非搜索引擎'
        } else if (row.clueSource == 39) {
            return '伽湾-直接访问'
        } else if (row.clueSource == 40) {
            return '商搜-百度'
        } else if (row.clueSource == 41) {
            return '商搜-360'
        } else if (row.clueSource == 42) {
            return '商搜-搜狗'
        } else if (row.clueSource == 43) {
            return '商搜-非搜索引擎'
        } else if (row.clueSource == 44) {
            return '商搜-直接访问'
        } else if (row.clueSource == 45) {
            return '嘉丁-百度'
        } else if (row.clueSource == 46) {
            return '嘉丁-360"'
        } else if (row.clueSource == 47) {
            return '嘉丁-搜狗'
        } else if (row.clueSource == 48) {
            return '嘉丁-非搜索引擎'
        } else if (row.clueSource == 49) {
            return '嘉丁-直接访问'
        } else if (row.clueSource == 50) {
            return '布谷-百度'
        } else if (row.clueSource == 51) {
            return '布谷-360'
        } else if (row.clueSource == 52) {
            return '布谷-搜狗'
        } else if (row.clueSource == 53) {
            return '布谷-非搜索引擎'
        } else if (row.clueSource == 54) {
            return '布谷-直接访问'
        } else if (row.clueSource == 55) {
            return '三木-百度'
        } else if (row.clueSource == 56) {
            return '三木-360'
        } else if (row.clueSource == 57) {
            return '三木-搜狗'
        } else if (row.clueSource == 58) {
            return '三木-非搜索引擎'
        } else if (row.clueSource == 59) {
            return '三木-直接访问'
        } else if (row.clueSource == 101) {
            return '钱邮-360'
        } else if (row.clueSource == 102) {
            return '钱邮-百度'
        } else if (row.clueSource == 103) {
            return '钱邮-搜狗'
        } else if (row.clueSource == 104) {
            return '微易-360'
        } else if (row.clueSource == 105) {
            return '微易-百度'
        } else if (row.clueSource == 106) {
            return '微易-搜狗'
        } else if (row.clueSource == 107) {
            return '微易-直接访问'
        } else if (row.clueSource == 108) {
            return '钱邮-直接访问'
        } else {
            return '- -'
        }
    },
    clueType(clueType) {
        if (clueType == 1) {
            return '售前咨询'
        } else if (clueType == 2) {
            return '购买咨询'
        } else if (clueType == 3) {
            return '售后'
        } else if (clueType == 4) {
            return '基础版注册'
        } else {
            return '- -'
        }
    },
    clueStatus(clueStatus) {
        if (clueStatus == 4) {
            return '无效'
        } else if (clueStatus == 5) {
            return '跟进中'
        } else if (clueStatus == 8) {
            return '已完成'
        } else if (clueStatus == 12) {
            return '待分配'
        } else if (clueStatus == 13) {
            return '已撤回'
        } else if (clueStatus == 14) {
            return '已分配'
        } else if (clueStatus == 15) {
            return '已放弃'
        } else {
            return '- -'
        }
    },
    otherContact(row) {
        if (row.weChat) {
            return row.weChat
        } else if (row.qq) {
            return row.qq
        } else {
            return '- -'
        }
    },
    customerClueStatus(row) {
        if (row.customerClueStatus == 1) {
            return '未分配'
        } else if (row.customerClueStatus == 2) {
            return '待分配'
        } else if (row.customerClueStatus == 3) {
            return '待接收'
        } else if (row.customerClueStatus == 4) {
            return '待定'
        } else if (row.customerClueStatus == 5) {
            return '已退回'
        } else if (row.customerClueStatus == 6) {
            return '转为客户'
        } else if (row.customerClueStatus == 7) {
            return '商机'
        } else if (row.customerClueStatus == 8) {
            return '已完成'
        } else if (row.customerClueStatus == 9) {
            return '关闭'
        }
    },
    createTime(row) {
        if (row.createTime) {
            var today = new Date();
            var yesterday = new Date(today - 1000 * 60 * 60 * 24);
            var test = new Date(row.createTime);
            if (yesterday.getYear() === test.getYear() && yesterday.getMonth() === test.getMonth() && yesterday.getDate() === test.getDate()) {
                return '昨天' + row.createTime.substring(10, 16)
            } else if (today.getYear() === test.getYear() && today.getMonth() === test.getMonth() && today.getDate() === test.getDate()) {
                return '今天' + row.createTime.substring(10, 16)
            } else {
                return row.createTime.substring(0, 10)
            }
        } else {
            return '- -'
        }
    },
    startFollowDate(row) {
        if (row.startFollowDate) {
            let oldTimeFormat = new Date(row.startFollowDate);
            let nowDate = new Date();
            let times = nowDate.getTime() - oldTimeFormat.getTime();
            let days = parseInt(times / (60 * 60 * 24 * 1000));
            if (days == 0) {
                return '今天' + row.startFollowDate.substring(10, 16)
            } else if (days == 1) {
                return '昨天' + row.startFollowDate.substring(10, 16)
            } else {
                return row.startFollowDate.substring(0, 10)
            }
        } else {
            return '- -'
        }
    },
    customerTodoList(row) {
        let nowTime = new Date()
        if (row.customerTodoList.length > 0) {
            for (let i in row.customerTodoList) {
                let endTime = new Date(row.customerTodoList[i].todoTime)
                let t = endTime.getTime() - nowTime.getTime()
                let d = Math.floor(t / 1000 / 60 / 60 / 24)
                if (d > 0) {
                    return row.customerTodoList[i].todoTitle
                }
            }
        } else {
            return '- -'
        }
    },
    Report(row) {
        let nowTime = new Date()
        let aliTime = new Date(row.aliReportExpireTime)
        let tenTime = new Date(row.tencentReportExpireTime)
        let text = ''
        if (aliTime > nowTime) {
            text += '阿里'
        }
        if (tenTime > nowTime) {
            text += '腾讯'
        }
        if (text == '') {
            return '- -'
        } else {
            return text
        }
    },
    // 线索类型
    productCategory(productCategory) {
        if (productCategory == 1) {
            return '腾讯企业邮箱'
        } else if (productCategory == 2) {
            return '阿里企业邮箱'
        } else if (productCategory == 3) {
            return '云产品'
        } else if (productCategory == 4) {
            return '网易企业邮箱'
        } else if (productCategory == 5) {
            return '网站'
        } else if (productCategory == 6) {
            return '投屏盒子'
        } else if (productCategory == 7) {
            return '微盘'
        } else if (productCategory == 8) {
            return '虚拟主机'
        } else if (productCategory == 9) {
            return '服务器'
        } else if (productCategory == 10) {
            return '会话存档接口'
        } else if (productCategory == 11) {
            return '企业微信'
        } else if (productCategory == 12) {
            return '域名'
        } else if (productCategory == 13) {
            return '飞连'
        } else if (productCategory == 14) {
            return '会话存档系统'
        } else {
            return '- -'
        }
    },
    // 所属行业
    industry(row) {
        if (row.business == 1001) {
            return "制造业"
        } else if (row.business == 1002) {
            return "互联网（IT服务）"
        } else if (row.business == 1003) {
            return "批发/零售"
        } else if (row.business == 1004) {
            return "生活服务"
        } else if (row.business == 1005) {
            return "文化/体育/娱乐业"
        } else if (row.business == 1006) {
            return "建筑/房地产"
        } else if (row.business == 1007) {
            return "教育"
        } else if (row.business == 1008) {
            return "金融"
        } else if (row.business == 1009) {
            return "医疗"
        } else if (row.business == 1010) {
            return "政府"
        } else if (row.business == 1011) {
            return "运输/物流/仓储"
        } else if (row.business == 1012) {
            return "能源/采矿"
        } else if (row.business == 1013) {
            return "农业渔牧"
        } else if (row.business == 1000) {
            return "其他行业"
        } else {
            return '- -'
        }
    },
    // 邮箱版本
    // emailVersionType(row) {
    //     if (row.emailVersionType == 1) {
    //         return '全新用户'
    //     } else if (row.emailVersionType == 2) {
    //         return '已有新版基础版'
    //     } else if (row.emailVersionType == 3) {
    //         return '已有旧版基础版'
    //     } else if (row.emailVersionType == 4) {
    //         return '阿里-标准版'
    //     } else if (row.emailVersionType == 5) {
    //         return '阿里-尊享版'
    //     } else if (row.emailVersionType == 6) {
    //         return '阿里-集团版'
    //     } else {
    //         return '- -'
    //     }
    // },
    // 联系方式
    contactWay(row) {
        if (row.contactWay == 1) {
            return '电话'
        } else if (row.contactWay == 2) {
            return '微信'
        } else if (row.contactWay == 3) {
            return '邮箱'
        } else if (row.contactWay == 4) {
            return 'QQ'
        } else if (row.contactWay == 5) {
            return '短信'
        } else if (row.contactWay == 6) {
            return '拜访'
        } else if (row.contactWay == 7) {
            return '其他'
        } else if (row.contactWay == 8) {
            return '官网表单'
        } else if (row.contactWay == 9) {
            return '座机'
        } else {
            return '- -'
        }
    },
    // 联系方式图标和颜色
    contactWayColor(row) {
        if (row.contactWay == 1) {
            return {
                color: "#2370EB",
                icon: "iconfont icon-dianhua"
            }
        } else if (row.contactWay == 2) {
            return {
                color: "#50B674",
                icon: "iconfont icon-weixin"
            }
        } else if (row.contactWay == 3) {
            return {
                color: "#FEB516",
                icon: "iconfont icon-youxiang1"
            }
        } else if (row.contactWay == 4) {
            return {
                color: "#EB1C26",
                icon: "iconfont icon-QQ"
            }
        } else if (row.contactWay == 5) {
            return {
                color: "#13AB16",
                icon: "iconfont icon-duanxinxiaoxixinxi"
            }
        } else if (row.contactWay == 6) {
            return {
                color: "#F15641",
                icon: "iconfont icon-yuyuebaifang"
            }
        } else if (row.contactWay == 9) {
            return {
                color: "#87B1E9",
                icon: "iconfont icon-diannao"
            }
        } else if (row.contactWay == 8) {
            return {
                color: "#6A58CA",
                icon: "iconfont icon-zuoji"
            }
        } else {
            return {
                color: "#AF90FF",
                icon: "iconfont icon-qita1"
            }
        }
    },
    // // 线索状态
    // clueStatus(status) {
    //     switch (status) {
    //         case 1:
    //             return '未联系';
    //         case 2:
    //             return '已编辑';
    //         case 3:
    //             return '处理中'; 
    //         case 4:
    //             return '无效';
    //         case 5:
    //             return '跟进中';
    //         case 6:
    //             return '搁置';
    //         case 7:
    //             return '输单';
    //         case 8:
    //             return '已成交';
    //         case 9:
    //             return '待共享';
    //         case 10:
    //             return '撤销';
    //         default:
    //             return '- -'
    //     }
    // },
    // 投放渠道 
    deliveryChannel(val1, val2) {
        let arr1 = [
            {
                label: "伽搜",
                value: 1
            },
            {
                label: "伽湾",
                value: 2
            },
            {
                label: "商搜",
                value: 3
            },
            {
                label: "嘉丁",
                value: 4
            },
            {
                label: "布谷",
                value: 5
            },
            {
                label: "三木",
                value: 6
            },
            {
                label: "钱邮",
                value: 7
            },
            {
                label: "微易",
                value: 8
            },
            {
                label: "云动",
                value: 9
            },
            {
                label: "起量",
                value: 10
            },
            {
                label: "品服",
                value: 11
            },
            {
                label: "众拓",
                value: 12
            },
            {
                label: "辰宿",
                value: 13
            },
            {
                label: "非线性",
                value: 14
            },
            {
                label: "其他",
                value: 15
            },
        ]
        let arr2 = [
            {
                label: "百度",
                value: 1
            },
            {
                label: "360",
                value: 2
            },
            {
                label: "搜狗",
                value: 3
            },
            {
                label: "非搜索引擎",
                value: 4
            },
            {
                label: "直接访问",
                value: 5
            },
        ]
        let deliveryChannelPlatform = '', deliveryChannel = ""
        arr1.forEach(item => {
            if (item.value == val1) {
                return deliveryChannelPlatform = item.label
            }
        })
        arr2.forEach(item => {
            if (item.value == val2) {
                return deliveryChannel = item.label
            }
        })
        // console.log(deliveryChannelPlatform, deliveryChannel)
        if (deliveryChannelPlatform && deliveryChannel) {
            return deliveryChannelPlatform + '-' + deliveryChannel
        } else {
            return '- -'
        }
    },
    // 留资方式
    retentionMethod(status) {
        switch (status) {
            case 1:
                return '添加微信';
            case 2:
                return '400电话';
            case 3:
                return '在线留言';
            case 4:
                return '官网表单';
            case 5:
                return '邮件';
            case 6:
                return '自拓';
            case 7:
                return '其他';
            default:
                return '- -'
        }
    },
    // 阿拉伯数字转大写
    ToString(n) {
        if (!n) {
            return "零圆";
        }
        var fraction = ['角', '分'];
        var digit = [
            '零', '壹', '贰', '叁', '肆',
            '伍', '陆', '柒', '捌', '玖'
        ];
        var unit = [
            ['元', '万', '亿'],
            ['', '拾', '佰', '仟']
        ];
        var head = n < 0 ? '欠' : '';
        n = Math.abs(n);
        var s = '';
        for (var i = 0; i < fraction.length; i++) {
            s += (digit[Math.floor(n * 10 * Math.pow(10, i)) % 10] + fraction[i]).replace(/零./, '');
        }
        s = s || '整';
        n = Math.floor(n);
        for (var i = 0; i < unit[0].length && n > 0; i++) {
            var p = '';
            for (var j = 0; j < unit[1].length && n > 0; j++) {
                p = digit[n % 10] + unit[1][j] + p;
                n = Math.floor(n / 10);
            }
            s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s;
        }
        return head + s.replace(/(零.)*零元/, '元')
            .replace(/(零.)+/g, '零')
            .replace(/^整$/, '零元整');
        // if (!n) {
        //     return "零圆";
        // }
        // if (!/^(0|[1-9]\d*)(\.\d+)?$/.test(n)) {
        //     return "零圆";  //判断数据是否大于0
        // }
        // var unit = "千百拾亿千百拾万千百拾元角分", str = "";
        // n += "00";
        // var indexpoint = n.indexOf('.');  // 如果是小数，截取小数点前面的位数
        // if (indexpoint >= 0) {
        //     n = n.substring(0, indexpoint) + n.substr(indexpoint + 1, 2);   // 若为小数，截取需要使用的unit单位
        // }
        // unit = unit.substr(unit.length - n.length);  // 若为整数，截取需要使用的unit单位
        // for (var i = 0; i < n.length; i++) {
        //     str += "零壹贰叁肆伍陆柒捌玖".charAt(n.charAt(i)) + unit.charAt(i);  //遍历转化为大写的数字
        // }
        // return str.replace(/零(千|百|拾|角)/g, "零").replace(/(零)+/g, "零").replace(/零(万|亿|元)/g, "$1").replace(/(亿)万|壹(拾)/g, "$1$2").replace(/^元零?|零分/g, "").replace(/元$/g, "元"); // 替换掉数字里面的零字符，得到结果
    },
    // 销售费用状态
    costStatus(status) {
        if (status == 1 || status == 2 || status == 3) {
            return "待审批"
        } else if (status == 8 || status == 7 || status == 6) {
            return "已驳回"
        } else if (status == 9) {
            return "已撤销"
        } else if (status == 5) {
            return "已关联"
        } else if (status == 4) {
            return "已通过"
        } else if (status == 10) {
            return "重新编辑"
        } else {
            return '- -'
        }
    },
    // 报销状态
    reimbStatus(status) {
        if (status == 1 || status == 2 || status == 3) {
            return "待审批"
        } else if (status == 8 || status == 7 || status == 6) {
            return "已驳回"
        } else if (status == 9) {
            return "已撤销"
        } else if (status == 5) {
            return "已关联"
        } else if (status == 4) {
            return "已通过"
        } else if (status == 10) {
            return "重新编辑"
        } else {
            return '- -'
        }
    },
    // 域名品牌展示
    dominBrand(domainMX) {
        // if (domainMX.indexOf("mxhichina.com") != -1 || domainMX.indexOf("alibaba.com") != -1) {
        //     return '阿里云';
        // }
        // if (domainMX.indexOf("mxbiz.qq.com") != -1) {
        //     return '腾讯';
        // }
        // if (domainMX.indexOf("263.net") != -1 || domainMX.indexOf("ismail.cn") != -1) {
        //     return '263';
        // }
        // if (domainMX.indexOf("mail.netease.com") != -1 || domainMX.indexOf("qiye.163") != -1 || domainMX.indexOf("netease.com") != -1) {
        //     return '网易收费版';
        // }
        // if (domainMX.indexOf("ym.163.com") != -1) {
        //     return '网易免费版';
        // }
        // if (domainMX.indexOf("cn4e.com") != -1 || domainMX.indexOf("baotongtex.com") != -1) {
        //     return '35互联';
        // }
        if (domainMX.indexOf("chengmail.cn") != -1) {
            return '美橙互联';
        }
        // if (domainMX.indexOf("icoremail.net") != -1) {
        //     return '盈世';
        // }
        if (domainMX.indexOf("mta-ent.21cn.com") != -1) {
            return '电信';
        }
        // if (domainMX.indexOf("zmail1300.cn") != -1 || domainMX.indexOf("zmail.net.cn") != -1) {
        //     return '中企动力';
        // }
        // if (domainMX.indexOf("chinaemail.cn") != -1) {
        //     return '中资源';
        // }
        if (domainMX.indexOf("chinanetsun.com") != -1 || domainMX.indexOf("hi2000.com") != -1) {
            return '网盛生意宝';
        }
        // if (domainMX.indexOf("域名") != -1 || domainMX.indexOf("IP") != -1) {
        //     return '自建';
        // }
        if (domainMX.indexOf("icewarp.cn") != -1) {
            return '爱思华宝';
        }
        if (domainMX.indexOf("dns.com.cn") != -1 || domainMX.indexOf("global-mail.cn") != -1) {
            return '新网';
        }
        if (domainMX.indexOf("east.net") != -1) {
            return '东方网景';
        }
        if (domainMX.indexOf("upanshi.com") != -1) {
            return '磐邮';
        }


        if (domainMX.indexOf("huayan.net") != -1 || domainMX.indexOf("4cun.com") != -1) {
            return 'FangMail';
        }
        if (domainMX.indexOf("qcb.com.cn") != -1) {
            return '遥志DBMail';
        }
        if (domainMX.indexOf("spamgateway.cn") != -1) {
            return 'U-Mail';
        }
        if (domainMX.indexOf("faidns.com") != -1 || domainMX.indexOf("faipod.com") != -1) {
            return '凡科';
        }
        if (domainMX.indexOf("fangmail.net") != -1) {
            return '方向标';
        }
        if (domainMX.indexOf("smtp.hisun-pfizer.com") != -1) {
            return '辉正';
        }
        if (domainMX.indexOf("g.relaypod.net") != -1) {
            return 'RlayPod企业邮';
        }
        if (domainMX.indexOf("mx.sina.net") != -1) {
            return '新浪';
        }

        if (domainMX.indexOf("mx1.cneol.net") != -1 || domainMX.indexOf("corpease.net") != -1) {
            return 'Coremail论客';
        }
        if (domainMX.indexOf("edge.hostuc.net") != -1 || domainMX.indexOf("minthgroup.com") != -1 || domainMX.indexOf("mail.protection.outlook.com") != -1) {
            return '微软/outlook';
        }
        if (domainMX.indexOf("zte.com.cn") != -1) {
            return '中兴';
        }


        if (domainMX.indexOf("protonmail.ch") != -1) {
            return 'ProtonMail';
        }


        if (domainMX.indexOf("now.net.cn") != -1) {
            return '时代互联';
        }
        if (domainMX.indexOf("net269.cn") != -1) {
            return '迅邮';
        }
        if (domainMX.indexOf("6464.cn") != -1) {
            return '全球邮';
        }
        if (domainMX.indexOf("mx01.ufhost.com") != -1 || domainMX.indexOf("yienter.net") != -1) {
            return '企业邮局';
        }
        if (domainMX.indexOf("mimecast.com") != -1) {
            return '华克斯';
        }
        if (domainMX.indexOf("yunrelay.com") != -1) {
            return '云邮';
        }
        if (domainMX.indexOf("mx1.emailsrvr.com") != -1) {
            return 'Webmail';
        }
        if (domainMX.indexOf("smtp.asia.secureserver.net") != -1) {
            return 'godaddy邮局';
        }
        if (domainMX.indexOf("mfdns.com") != -1) {
            return '翼邮/YMail';
        }
        if (domainMX.indexOf("mx.cloud86.cn") != -1) {
            return '今科云邮箱';
        }
        if (domainMX.indexOf("mx1.sxy.top") != -1) {
            return '省心邮';
        }
        if (domainMX.indexOf("zoho.com.cn") != -1) {
            return 'zoho邮箱';
        }
        if (domainMX.indexOf("corpemail.net") != -1) {
            return '靠谱邮';
        }


        if (domainMX.indexOf("qq.com") != -1) {
            return '腾讯邮箱'
        }

        if (domainMX.indexOf("mxmail.netease.com") != -1 || domainMX.indexOf("qiye.163.com") != -1) {
            return '网易邮箱'
        }
        if (domainMX.indexOf("ym.163.com") != -1) {
            return '网易免费版'
        }
        if (domainMX.indexOf("qiye.aliyun.com") != -1 || domainMX.indexOf("mxhichina.com") != -1) {
            return '阿里邮箱'
        }
        if (domainMX.indexOf("263.net") != -1 || domainMX.indexOf("263xmail.com") != -1) {
            return '263邮箱'
        }
        if (domainMX.indexOf("cn4e.com") != -1) {
            return '35互联'
        }
        if (domainMX.indexOf("chinaemail.cn") != -1) {
            return '中资源'
        }
        if (domainMX.indexOf("icoremail.net") != -1) {
            return '盈世'
        }
        if (domainMX.indexOf("zmail300.cn") != -1) {
            return '中企动力'
        }
        if (domainMX.indexOf("21cn.com") != -1) {
            return '21cn邮箱'
        }
        if (domainMX.indexOf("partner.outlook.cn") != -1) {
            return '微软邮箱'
        }
        if (domainMX.indexOf("aspmx.google.com") != -1) {
            return '谷歌邮箱'
        }
        if (domainMX.indexOf("sxy.top") != -1) {
            return '省心邮'
        }
        if (domainMX.indexOf("mail.alibaba.com") != -1) {
            return '阿里巴巴邮'
        }
        if (domainMX.indexOf("mx3.feishu.cn") != -1 || domainMX.indexOf("mx2.feishu.cn") != -1 || domainMX.indexOf("mx1.feishu.cn") != -1) {
            return '飞书'
        }
        if (domainMX.indexOf("UnKnown") != -1 || domainMX.indexOf("NXDOMAIN") != -1) {
            return '无'
        }
        return '其他邮箱'

    },
    getTextWidth(columnContent) {
        let flexWidth = 0
        for (let char of columnContent) {
            if ((char >= 'A' && char <= 'Z') || (char >= 'a' && char <= 'z')) {
                // 如果是英文字符，为字符分配8个单位宽度
                flexWidth += 12;
            } else if (char >= '\u4e00' && char <= '\u9fa5') {
                // 如果是中文字符，为字符分配15个单位宽度
                flexWidth += 20;
            } else {
                // 其他种类字符，为字符分配8个单位宽度
                flexWidth += 12;
            }
        }
        return flexWidth
    },

    // 投放渠道
    launchType(channelType) {
        if (channelType == 1) {
            return "百度"
        }
        if (channelType == 2) {
            return "360"
        }
        if (channelType == 3) {
            return "搜狗"
        }
        return '- -'
    },
    // 投放广告类型
    launchAdvertType(adType) {
        if (adType == 1) {
            return '竞价'
        }
        if (adType == 2) {
            return '品牌'
        }
        if (adType == 3) {
            return '丝路'
        }
        return '- -'
    },
    // 投放比率
    getYesterdayData(yesterdayList, dayData) {
        let arr = yesterdayList.find((item) => item.productId == dayData.productId) || {}
        return arr
    },
    launchRatio(yesterdayData, dayData) {
        if (dayData > 0) {
            if (yesterdayData > 0) {
                let bool = dayData - yesterdayData > 0 ? true : false
                return [bool, bool ? '+' + ((dayData - yesterdayData) / yesterdayData * 100).toFixed(2) + '%' : ((dayData - yesterdayData) / yesterdayData * 100).toFixed(2) + '%'];
            } else {
                return [true, '+100%']
            }
        } else {
            if (yesterdayData > 0) {
                return [false, '-100%'];
            } else {
                return [true, '+0%']
            }
        }
    },
    // 充值状态
    rechargeStatus(status) {
        if (status == 1) {
            return "审批中"
        }
        if (status == 2) {
            return "待支付"
        }
        if (status == 3) {
            return "驳回"
        }
        if (status == 4) {
            return "已支付"
        }
        if (status == 5) {
            return "撤销"
        }

        return "- -"
    },
    // 根据充值状态获取充值状态数据总数
    getRechargeMount(jurisdiction, dataList) {
        switch (jurisdiction) {
            case 'auditing':
                return dataList.find(item => item.status == 1) ? dataList.find(item => item.status == 1).statusCount : 0
            case 'toPay':
                return dataList.find(item => item.status == 2) ? dataList.find(item => item.status == 2).statusCount : 0
            case 'rejected':
                return dataList.find(item => item.status == 3) ? dataList.find(item => item.status == 3).statusCount : 0
            case 'Paid':
                return dataList.find(item => item.status == 4) ? dataList.find(item => item.status == 4).statusCount : 0
            case 'revoked':
                return dataList.find(item => item.status == 5) ? dataList.find(item => item.status == 5).statusCount : 0

        }
    },
    // 商机类型
    boType(type) {
        switch (type) {
            case 1:
                return "续费"
            case 2:
                return "扩用户"
            case 3:
                return "扩容续费"
            default:
                return '- -'
        }
    },
    // 商机进度
    boStatus(status) {
        switch (status) {
            case 1:
                return "跟进中"
            case 2:
                return "方案确定"
            case 3:
                return "签约"
            case 4:
                return "回款"
            case 5:
                return "流失"
            case 6:
                return "放弃"

            default:
                return "- -"
        }
    },
    // 已成交客户报备产品类型
    productType(type) {
        switch (type) {
            case 1:
                return "腾讯邮箱"
            case 2:
                return "阿里邮箱"
            default:
                return "- -"
        }
    },
    // 截取字符
    subStr(num, text) {
        if (text && text.length > num) {
            return text.substring(0, num)
        } else {
            return text
        }
    },
    // 乙方
    typeB(typeB) {
        switch (typeB) {
            case 0:
                return "二维码收款"
            case 1:
                return "伽搜-对公"
            case 2:
                return "伽湾-对公"
            case 3:
                return "钱邮-对公"
            case 4:
                return "非线性-对公"
            case 5:
                return "伽搜-微信"
            case 6:
                return "伽搜-支付宝"
            case 7:
                return "钱邮-微信"
            case 8:
                return "钱邮-支付宝"
            case 9:
                return "自定义"
            case 10:
                return "浙江伽搜科技有限公司"
            case 11:
                return "杭州伽湾信息技术有限公司"
            case 12:
                return "杭州钱邮科技有限公司"
            case 13:
                return "苏州企邮会信息技术有限公司"
            case 14:
                return "杭州非线性科技有限公司"
            case 15:
                return "杭州辰宿科技有限公司"
            case 16:
                return "上海瑞外纽信息技术有限公司"
            case 17:
                return "成都预明科技有限公司"
            case 18:
                return "杭州商邮科技有限公司"
            case 19:
                return "上海云璞信息技术有限公司"
            case 20:
                return "浙江邮点好信息技术有限公司"
            case 21:
                return "上海遨邮科技有限公司"
            case 22:
                return "苏州一霸信息科技有限公司"
            default:
                return "- -"
        }
    },
    echoTypeB(typeB) {
        switch (typeB) {
            case 0:
                return "- -"
            case 9:
                return "自定义"
            case 10:
                return "浙江伽搜科技有限公司"
            case 11:
                return "杭州伽湾信息技术有限公司"
            case 12:
                return "杭州钱邮科技有限公司"
            case 13:
                return "苏州企邮会信息技术有限公司"
            case 14:
                return "杭州非线性科技有限公司"
            case 15:
                return "杭州辰宿科技有限公司"
            case 16:
                return "上海瑞外纽信息技术有限公司"
            case 17:
                return "成都预明科技有限公司"
            case 18:
                return "杭州商邮科技有限公司"
            case 19:
                return "上海云璞信息技术有限公司"
            case 20:
                return "浙江邮点好信息技术有限公司"
            case 21:
                return "上海遨邮科技有限公司"
            case 22:
                return "苏州一霸信息科技有限公司"
            default:
                return "- -"
        }
    },
    // 选项
    selectTypeB() {
        let arr = [
            {
                label: "浙江伽搜科技有限公司",
                value: 10
            },
            {
                label: "杭州伽湾信息技术有限公司",
                value: 11
            },
            {
                label: "杭州钱邮科技有限公司",
                value: 12
            },
            {
                label: "苏州企邮会信息技术有限公司",
                value: 13
            },
            {
                label: "杭州非线性科技有限公司",
                value: 14
            },
            {
                label: "杭州辰宿科技有限公司",
                value: 15
            },
            {
                label: "上海瑞外纽信息技术有限公司",
                value: 16
            },
            {
                label: "成都预明科技有限公司",
                value: 17
            },
            {
                label: "杭州商邮科技有限公司",
                value: 18
            },
            {
                label: "上海云璞信息技术有限公司",
                value: 19
            },
            {
                label: "浙江邮点好信息技术有限公司",
                value: 20
            },
            {
                label: "上海遨邮科技有限公司",
                value: 21
            },
            {
                label: "苏州一霸信息科技有限公司",
                value: 22
            },
            {
                label: "自定义",
                value: 9
            },
        ]
        return arr
    },
    // 入账方式
    entryWay(status) {
        if (status == 1) {
            return '对公转账'
        }
        if (status == 2) {
            return '微信'
        }
        if (status == 3) {
            return '支付宝'
        }
        if (status === 0) {
            return '二维码收款'
        }
        // if (status == 4) {
        //     return '扫码支付'
        // }
        if (status == 5) {
            return '企业微信收款'
        }
        return '- -'
    },
    entryWaySec() {
        let arr = [
            {
                label: "二维码收款",
                value: 0
            },
            {
                label: "对公转账",
                value: 1
            },
            {
                label: "微信",
                value: 2
            },
            {
                label: "支付宝",
                value: 3
            },
            // {
            //     label: "扫码支付",
            //     value: 4
            // },
            {
                label: "企业微信收款",
                value: 5
            },
        ]
        return arr
    },
    billStatus(status) {
        if (status == 1) {
            return "待发送"
        }
        if (status == 2) {
            return "待确认"
        }
        if (status == 3) {
            return "已确认"
        }
        if (status == 4) {
            return "已驳回"
        }
        if (status == 5) {
            return "已过期"
        }

        return "- -"

    },
    // 工资条状态
    wagesStatus(status) {
        if (status == 1) {
            return "待发送"
        }
        if (status == 2) {
            return "待查看"
        }
        if (status == 3) {
            return "待确认"
        }
        if (status == 4) {
            return "已确认"
        }
        if (status == 5) {
            return "已反馈"
        }
        if (status == 6) {
            return "已撤回"
        }
        return "- -"
    }
}


