import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        labelList: [], //标签集合
    },
    //状态管理
    mutations: {
        // 获取标签集合
        getLabelList(state, params) {
            state.labelList = JSON.parse(params)
        },
        // 移除标签
        removeLabel(state, params) {
            let data= JSON.parse(params)
            for (let i in state.labelList) {
                if (state.labelList[i].id == data.id) {
                    state.labelList.splice(i ,1)
                }
            }
        },
        // 清空
        emptyData(state) {
            state.isDetails = false
            state.isMultiple = false
            state.type = ''
            state.labelList = []
            state.MaillabelList=[]
        },
    },
    //修改state
    actions: {
    },
})
export default store
