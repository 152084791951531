export default { 
    // 管理员问题
    adminProblem(arr) {
        let obj = [
            {
                label: "通讯录相关",
                value: 1
            },
            {
                label: "权限设置",
                value: 2
            },
            {
                label: "管理工具",
                value: 3
            },
             {
                label: "邮箱注册",
                value: 5
            },
            {
                label: "其他",
                value: 4
            },
        ]
        return (arr = obj)
    },
    // 成员问题
    userProblem(arr) {
        let obj = [
            {
                label: "登录",
                value: 1
            },
            {
                label: "收发信",
                value: 2
            },
            {
                label: "功能设置",
                value: 3
            },
            {
                label: "其他",
                value: 4
            },
        ]
        return (arr = obj)
    },
    // 客户端问题
    clientProblem(arr) {
        let obj = [
            {
                label: "foxmail客户端",
                value: 1
            },
            {
                label: "outlook客户端",
                value: 2
            },
            {
                label: "企业微信客户端",
                value: 3
            },
            {
                label: "手机端",
                value: 4
            },
            {
                label: "其他",
                value: 5
            },
        ]
        return (arr = obj)
    },
}