import axios from 'axios';
import router from '@/router/router';
import { message } from './resetMessage';
import { Loading } from 'element-ui';
import Vue from "vue"
let vm = new Vue()


let requestCount = 0;
let loading;
// (客户端请求前)显示loading
function showLoading() {
     if (requestCount === 0) {
          loading = Loading.service({
               lock: true,
               text: '数据加载中~',
               spinner: "el-icon-loading",    //loading样式类名
               background: "rgba(0,0,0,0.6)"
          })
     }
     requestCount++
}
let timer;
// (服务器响应后)尝试隐藏loading
function tryHideLoading() {
     requestCount--
     //采用setTimeout是为了解决一个请求结束后紧接着有另一请求发起导致loading闪烁的问题
     timer = setTimeout(() => {
          if (requestCount === 0) {
               loading.close()
               clearTimeout(timer)
          }
     })
}
const service = axios.create({
     // baseURL: 'http://81.68.198.224:8021/',
     baseURL: 'http://81.68.198.224:8020/webboss',
     timeout: 6 * 10000,
});
service.interceptors.request.use(
     (config) => {
          if (!sessionStorage.getItem('accessToken')) {
               showLoading()
               return config;
          } else {
               config.headers.Authorization = 'Bearer' + ' ' + sessionStorage.getItem('accessToken');
               if (!sessionStorage.getItem("customerlist")) {
                    showLoading()
               }
               return config;
          }
     },
     (error) => {
          return Promise.reject(error);
     },
);
service.interceptors.response.use(
     (response) => {

          if (!sessionStorage.getItem("customerlist")) {
               tryHideLoading()
          }
          if (response.status == 200) {
               if (response.data.status == 200) {
                    return Promise.resolve(response.data);
               } else {
                    switch (response.data.status) {
                         case 401:
                              message.error('验证失败');
                              break;
                         case 415:
                              message.error('该手机号不存在');
                              break;
                         case 460:
                              message.error('验证码错误');
                              break;
                         case 413:
                              message.error('没有相应的权限');
                              break;
                         case 403:
                              message.error('没有相应的权限');
                              break;
                         case 461:
                              message.error('已经存在');
                              break;
                         case 411:
                              message.error('无法删除，请先移除该部门下的管理员或子部门');
                              break;
                         case 470:
                              message.error('数据库操作失败');
                              break;
                         case 208:
                              message.error('该数据已经存在');
                              break;
                         case 405:
                              message.error('手机号已经存在');
                              break;
                         case 462:
                              message.error('企业微信号已经存在');
                              break;
                         case 471:
                              message.error('无权操作');
                              break;
                         case 478:
                              message.error('该数据无法操作');
                              break;
                         case 463:
                              message.error('数据库不存在该记录');
                              break;
                         case 464:
                              message.error('该线索已经被领取');
                              break;
                         case 465:
                              message.error('代办的提醒时间已过期');
                              break;
                         case 466:
                              message.error('客户状态异常');
                              break;
                         case 467:
                              message.error('合同已作废或者已关联其他业绩，请重新选择');
                              break;
                         case 468:
                              message.error('已核销状态不允许修改');
                              break;
                         case 469:
                              message.error('金额不匹配');
                              break;
                         case 472:
                              message.error('客户是已成交状态不允许绑定线索');
                              break;
                         case 473:
                              message.error('业绩预估不能是已到款状态');
                              break;
                         case 410:
                              message.error('不存在该企业');
                              break;
                         case 406:
                              message.error('企业中不存在该成员');
                              break;
                         case 474:
                              message.error('当前线索状态不允许修改');
                              break;
                         case 475:
                              message.error('合同作废状态下不能操作');
                              break;
                         case 476:
                              message.error('业绩已经系统自动匹配全部到款');
                              break;
                         case 477:
                              message.error('数据库不存在自动分配角色的用户');
                              break;
                         case 400:
                              message.error('操作失败');
                              break;
                         case 500:
                              message.error('内部错误，请联系管理员');
                              break;
                         case 479:
                              message.error('该状态下不能撤销');
                              break;
                         case 480:
                              message.error('域名校验不通过');
                              break;
                         case 481:
                              message.error('当前有一条申请中正在处理的数据不允许再申请');
                              break;
                         case 482:
                              message.error('无权操作');
                              break;
                         case 483:
                              message.error('分配名额不正确');
                              break;
                         case 484:
                              message.error('报备的名额大于分配的最大名额了');
                              break;
                         case 485:
                              message.error('该品牌分类下，报备库当前可最大报备数总和不能小于已分配名额');
                              break;
                         case 486:
                              message.error('回收时间小于当前时间');
                              break;
                         case 486:
                              message.error('部门分配成功');
                              break;
                         case 489:
                              message.error('客户名称重复，无法创建');
                              break;
                         case 490:
                              message.error('关联合同状态异常，无法申请');
                              break;
                         case 491:
                              message.error('回款已经匹配或金额不匹配或状态不正确');
                              break;
                         case 492:
                              message.error('模板和印章不匹配');
                              break;
                         case 493:
                              message.error('该合同已经被其他人审核过了，无需再审核');
                              break;
                         case 494:
                              message.error('该账单已过期无法操作');
                              break;
                         case 495:
                              message.error('该订单已经确认 无法修改');
                              break;
                         case 496:
                              message.error('上个月的全部订单只能在本月的1-10号生成');
                              break;
                         case 497:
                              message.error('标签不允许重复添加');
                              break;
                         case 498:
                              message.error('已有未完成的报备信息');
                              break;
                         case 499:
                              message.error('配置了共享不允许换部门');
                              break;
                         case 1000:
                              message.error('角色删除失败');
                              break;
                         case 1001:
                              message.error('该产品有子项或者在标的中被使用');
                              break;
                         case 1002:
                              message.error('放弃客户需要先放弃或撤销此客户关联的商机');
                              break;
                         case 1003:
                              message.error('放弃客户需要先删除此客户关联的合同');
                              break;
                         case 1004:
                              message.error('已存在相同金额且未核销的到款记录，请处理后再尝试添加');
                              break;
                         case 1005:
                              message.error('开票记录金额和开票金额不一致，不允许绑定');
                              break;
                         case 1006:
                              message.error('当前报备的客户已经存在报备');
                              break;
                         case 1007:
                              message.error('当前报备的域名已经存在报备');
                              break;
                         case 1008:
                              message.error('当前客户仍在停报时间内，报备失败');
                              break;
                         case 407:
                              message.error('登录过期');
                              sessionStorage.clear();
                              router.push({
                                   path: `/login`,
                              });
                    }
                    return Promise.resolve(response.data);
               }
          } else {
               return Promise.reject(response.data);
          }
     },
     (error) => {
          tryHideLoading()
          if (error.response) {
               switch (error.response.status) {
                    case 404:
                         message.error = '找不到请求，请重试';
                         break;
                    default:
                         message.error = `连接错误${error.response.status}`;
               }
          } else {
               if (JSON.stringify(error).includes('timeout')) {
                    Message.error('服务器响应超时，请刷新当前页');
               }
               message.error('连接服务器失败');
          }

          message.error('系统错误请联系管理员');
          return Promise.resolve(error.response);
     },
);
export default service;