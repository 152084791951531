export default {
    industry(arr) {
    let json= [{
                value: 1001,
                label: '制造业'
            },
            {
                value: 1002,
                label: '互联网（IT服务）'
            },
            {
                value: 1003,
                label: '批发/零售'
            },
            {
                value: 1004,
                label: '生活服务'
            },
            {
                value: 1005,
                label: '文化/体育/娱乐业'
            },
            {
                value: 1006,
                label: '建筑/房地产'
            },
            {
                value: 1007,
                label: '教育'
            },
            {
                value: 1008,
                label: '金融'
            },
            {
                value: 1009,
                label: '医疗'
            },
            {
                value: 1010,
                label: '政府'
            },
            {
                value: 1011,
                label: '运输/物流/仓储 '
            },
            {
                value: 1012,
                label: '能源/采矿'
            },
            {
                value: 1013,
                label: '农业渔牧'
            },
            {
                value: 1000,
                label: '其他行业'
            },
    ]
        return (arr = json);
  }
}
    // let json = [
    //   {
    //     label: 'IT服务业',
    //     value: 1,
    //     children: [
    //       {
    //         label: '计算机软件 / 硬件 / 信息服务',
    //         value: 101,
    //       },
    //       {
    //         label: '互联网和相关服务',
    //         value: 102,
    //       },
    //       {
    //         label: 'IT服务业',
    //         value: 103,
    //       },
    //     ],
    //   },
    //   {
    //     label: '制造业',
    //     value: 2,
    //     children: [
    //       {
    //         label: ' 机械 / 电子',
    //         value: 201,
    //       },
    //       {
    //         label: ' 服装 / 纺织',
    //         value: 202,
    //       },
    //       {
    //         label: ' 汽车',
    //         value: 203,
    //       },
    //       {
    //         label: ' 金属制品',
    //         value: 204,
    //       },

    //       {
    //         label: '食品 / 饮料',
    //         value: 205,
    //       },
    //       {
    //         label: '家具 / 家纺',
    //         value: 206,
    //       },
    //       {
    //         label: ' 重工制造',
    //         value: 207,
    //       },
    //       {
    //         label: '家电 / 数码',
    //         value: 208,
    //       },
    //       {
    //         label: ' 橡胶 / 塑料',
    //         value: 209,
    //       },
    //       {
    //         label: '日用品 / 化妆品',
    //         value: 210,
    //       },
    //       {
    //         label: '化学原料制品',
    //         value: 211,
    //       },
    //       {
    //         label: '文教 / 工美 / 体育 / 娱乐用品',
    //         value: 212,
    //       },
    //       {
    //         label: '烟酒 / 茶',
    //         value: 213,
    //       },
    //       {
    //         label: '非金属矿物',
    //         value: 214,
    //       },
    //       {
    //         label: '其他',
    //         value: 215,
    //       },
    //     ],
    //   },
    //   {
    //     value: 3,
    //     label: '批发/零售',
    //     children: [
    //       {
    //         value: 301,
    //         label: '批发',
    //       },
    //       {
    //         value: 302,
    //         label: '零售',
    //       },
    //       {
    //         value: 303,
    //         label: '超市/便利店/百货商场',
    //       },
    //       {
    //         value: 304,
    //         label: '进出口',
    //       },
    //       {
    //         value: 305,
    //         label: '其他',
    //       },
    //     ],
    //   },
    //   {
    //     value: 4,
    //     label: '生活服务',
    //     children: [
    //       {
    //         value: 401,
    //         label: '餐饮',
    //       },
    //       {
    //         value: 402,
    //         label: '居民服务',
    //       },
    //       {
    //         value: 403,
    //         label: '租赁和商务服务',
    //       },
    //       {
    //         value: 404,
    //         label: '酒店/住宿',
    //       },
    //       {
    //         value: 405,
    //         label: '其他',
    //       },
    //     ],
    //   },
    //   {
    //     value: 5,
    //     label: '文化/体育/娱乐业',
    //     children: [
    //       {
    //         value: 501,
    //         label: '文化/体育',
    //       },
    //       {
    //         value: 502,
    //         label: '娱乐/旅游',
    //       },
    //       {
    //         value: 503,
    //         label: '新闻传媒',
    //       },

    //       {
    //         value: 504,
    //         label: '其他',
    //       },
    //     ],
    //   },
    //   {
    //     value: 6,
    //     label: '建筑/房地产',
    //     children: [
    //       {
    //         value: 601,
    //         label: '建筑业',
    //       },
    //       {
    //         value: 602,
    //         label: '建材装修',
    //       },
    //       {
    //         value: 603,
    //         label: '房地产',
    //       },

    //       {
    //         value: 604,
    //         label: '其他',
    //       },
    //     ],
    //   },
    //   {
    //     value: 7,
    //     label: '教育',
    //     children: [
    //       {
    //         value: 701,
    //         label: '学前教育',
    //       },
    //       {
    //         value: 702,
    //         label: '初中等教育',
    //       },
    //       {
    //         value: 703,
    //         label: '高等教育',
    //       },

    //       {
    //         value: 704,
    //         label: '培训机构',
    //       },
    //       {
    //         value: 705,
    //         label: '其他',
    //       },
    //     ],
    //   },
    //   {
    //     value: 8,
    //     label: '运输/物流/仓储',
    //     children: [
    //       {
    //         value: 801,
    //         label: '物流/仓储',
    //       },
    //       {
    //         value: 802,
    //         label: '道路/铁路运输',
    //       },
    //       {
    //         value: 803,
    //         label: '邮政/快递',
    //       },

    //       {
    //         value: 804,
    //         label: '航空运输',
    //       },
    //       {
    //         value: 805,
    //         label: '水上运输',
    //       },
    //       {
    //         value: 806,
    //         label: '其他',
    //       },
    //     ],
    //   },
    //   {
    //     value: 9,
    //     label: '医疗',
    //     children: [
    //       {
    //         value: 901,
    //         label: '医院/医疗机构',
    //       },
    //       {
    //         value: 902,
    //         label: '医疗器械',
    //       },
    //       {
    //         value: 903,
    //         label: '医药制造',
    //       },
    //       {
    //         value: 904,
    //         label: '医药流通',
    //       },

    //       {
    //         value: 905,
    //         label: '其他',
    //       },
    //     ],
    //   },
    //   {
    //     value: 10,
    //     label: '政府',
    //     children: [
    //       {
    //         value: 1001,
    //         label: '党政机关',
    //       },
    //       {
    //         value: 1002,
    //         label: '国家权力/行政机构',
    //       },
    //       {
    //         value: 1003,
    //         label: '检察院/法院/公安',
    //       },
    //       {
    //         value: 1004,
    //         label: '民政/人社/交通/卫生',
    //       },
    //       {
    //         value: 1005,
    //         label: '发改委/经信委/商务局/统计局',
    //       },
    //       {
    //         value: 1006,
    //         label: '国土/规划',
    //       },
    //       {
    //         value: 1007,
    //         label: '税务/海关/工商/环保/物价/药品',
    //       },
    //       {
    //         value: 1008,
    //         label: '政协/民主党派',
    //       },
    //       {
    //         value: 1009,
    //         label: '地方政府',
    //       },

    //       {
    //         value: 1010,
    //         label: '其他',
    //       },
    //     ],
    //   },
    //   {
    //     value: 11,
    //     label: '金融',
    //     children: [
    //       {
    //         value: 1101,
    //         label: '保险',
    //       },
    //       {
    //         value: 1102,
    //         label: '银行',
    //       },
    //       {
    //         value: 1103,
    //         label: '证券/投资/基金',
    //       },

    //       {
    //         value: 1104,
    //         label: '其他',
    //       },
    //     ],
    //   },
    //   {
    //     value: 12,
    //     label: '能源/采矿',
    //     children: [
    //       {
    //         value: 1201,
    //         label: '电力/热力/燃气/水供应业',
    //       },
    //       {
    //         value: 1202,
    //         label: '石油/天然气',
    //       },
    //       {
    //         value: 1203,
    //         label: '煤炭',
    //       },

    //       {
    //         value: 1204,
    //         label: '有色金属',
    //       },
    //       {
    //         value: 1205,
    //         label: '钢铁',
    //       },
    //       {
    //         value: 1206,
    //         label: '其他',
    //       },
    //     ],
    //   },
    //   {
    //     value: 13,
    //     label: '农林渔牧',
    //     children: [
    //       {
    //         value: 1301,
    //         label: '农林渔牧',
    //       },
    //     ],
    //   },
    //   {
    //     value: 14,
    //     label: '其他行业',
    //     children: [
    //       {
    //         value: 1401,
    //         label: '科学研究和技术服务业',
    //       },
    //       {
    //         value: 1402,
    //         label: '社会组织',
    //       },
    //       {
    //         value: 1403,
    //         label: '水利和环境管理',
    //       },

    //       {
    //         value: 1404,
    //         label: '国际组织',
    //       },
    //       {
    //         value: 1405,
    //         label: '其他',
    //       },
    //     ],
    //   },
    // ];

