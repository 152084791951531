import Vue from 'vue'
import VueRouter from 'vue-router'
const Login = () => import('@/views/login/login.vue');
// 管理员
const AdminNavigation = () => import('@/views/admin/components/navigation.vue')
const AdminFrame = () => import('@/views/admin/framework/framework.vue')//组织管理
const AdminRole = () => import('@/views/admin/role/role.vue')//角色管理

// 失效`
const Invalid = () => import('@/views/invalid/invalid.vue')
// 导航
const Navigation = () => import('@/views/user/navigation/navigation.vue');
// 工作台
const Workbench = () => import('@/views/user/workbench/workbench/workbench.vue')
const MyAudit = () => import("@/views/user/workbench/audit/myaudit/tableBox/tableBox.vue")
const Declareinfodetails = () => import("@/views/user/workbench/audit/myaudit/declare/details.vue")
const Submitted = () => import("@/views/user/workbench/audit/submitted/tableBox/tableBox.vue")

// 资料库
const Database = () => import('@/views/user/database/database/database.vue')
const DataBaseDetail = () => import("@/views/user/database/detail/detail.vue")
const AddDataBaseCus = () => import("@/views/user/database/addCustomer/addCustomer.vue")
// 线索
const Sale = () => import('@/views/user/clue/meClue/clueBox/clueBox.vue')
const Market = () => import('@/views/user/clue/marketClue/clueBox/clueBox.vue')
const AddClue = () => import('@/views/user/clue/meClue/addClue/addClue.vue')
const MarketAddClue = () => import('@/views/user/clue/compMarketClue/addClue/addClue.vue')
// const ClueDetails = () => import('@/views/user/clue/meClue/details/details.vue')
const MarketClueDetails = () => import('@/views/user/clue/compMarketClue/details/details.vue')
// const PublicClue = () => import('@/views/user/clue/publicClue/clueBox/clueBox.vue')
// const Cluedetails = () => import("@/views/user/clue/meClue/detail/detail.vue")
// const LmCluedetails = () => import("@/views/user/clue/meClue/lmDetail/detail.vue")
// const Cluedetails = () => import("@/views/user/clue/meClue/detail/detail.vue")
// const LmCluedetails = () => import("@/views/user/clue/meClue/lmDetail/detail.vue")
// 原意向客户线索列表
const IntentionCus = () => import("@/views/user/clue/intentionCus/customerBox/customerBox.vue")
// 线索详情

const ClueDetails = () => import("@/views/user/clue/details/detailsBox.vue")

// 部门线索
const DepSale = () => import('@/views/user/clue/depClue/depSale/clueBox/clueBox.vue')
const DepMarket = () => import('@/views/user/clue/depClue/depMarket/clueBox/clueBox.vue')
const DepClueDetail = () => import('@/views/user/clue/depClue/detail/detail.vue')
// 部门公海线索
const DepPublicClue = () => import('@/views/user/clue/depPublicClue/clueBox/clueBox.vue')
// 公司公海线索
const CompPublicClue = () => import('@/views/user/clue/compPublicClue/clueBox/clueBox.vue')
// 公司市场线索
const CompMarketClue = () => import('@/views/user/clue/compMarketClue/clueBox/clueBox.vue')
// 阿里市场线索
const AliMarketClue = () => import('@/views/user/clue/aliMarketClue/clueBox/clueBox.vue')
const AddAliclue = () => import('@/views/user/clue/aliMarketClue/addClue/addClue.vue')
const AliCluedetails = () => import('@/views/user/clue/aliMarketClue/details/details.vue')
// 自动分配设置
const SetAllocation = () => import('@/views/user/clue/setAllocation/clueBox/clueBox.vue')
// 市场线索统计
const MarketClueStaty = () => import("@/views/user/clue/marketClueStaty/tableBox/tableBox.vue")


// 潜在客户
const Latent = () => import('@/views/user/customer/meCustomer/customerBox/customerBox.vue')
// 成交客户
const Deali = () => import('@/views/user/customer/meCustomer/customerBox/customerBox.vue')

//客户计划
const CustomerRecord = () => import('@/views/user/customer/record/cRecord.vue')
const CustomerRecordDetail = () => import('@/views/user/customer/record/detailsRecord.vue')


const CustomerRecordHistory = () => import('@/views/user/customer/record/history/cRecordHistory.vue')

// 历史客户
const Historycust = () => import('@/views/user/customer/historyCust/historyCust.vue')

// 客户更多
const MoreDetails = () => import('@/views/user/customer/more/moreBox/moreBox.vue')

//商机
// const TmReporting = () => import('@/views/user/mailbox/tmReporting/tmReporting.vue') //腾讯报备
const TmReporting = () => import('@/views/user/mailbox/newTmReporting/tmReporting.vue') //新腾讯报备
const AliReporting = () => import('@/views/user/mailbox/aliReporting/aliReporting.vue') //阿里报备
const AliReportingdetails = () => import("@/views/user/mailbox/aliReporting/details/details.vue")//阿里报备详情
const WyReporting = () => import('@/views/user/mailbox/wyReporting/wyReporting.vue') //网易报备
const WyReportingdetails = () => import("@/views/user/mailbox/wyReporting/details/details.vue")//网易报备详情
const BoReporting = () => import("@/views/user/mailbox/boreporting/boreporting.vue") //老客户商机报备
const AllReportData = () => import("@/views/user/mailbox/boreporting/allData/allData.vue")
// const TmDempmail = () => import("@/views/user/mailbox/demp/dempTm.vue")//部门商机腾讯
const TmDempmail = () => import("@/views/user/mailbox/demp/newDempTm.vue")//新部门商机腾讯
const AliDempmail = () => import("@/views/user/mailbox/demp/dempAli.vue")//部门商机阿里
const WyDempmail = () => import("@/views/user/mailbox/demp/dempWy.vue")//部门商机网易
// const TmDetail = () => import("@/views/user/mailbox/tmDetail/tmDetail.vue")//腾讯报备详情
const TmDetail = () => import("@/views/user/mailbox/newTmReporting/details/tmDetail.vue")//新腾讯报备详情
const DealWith = () => import("@/views/user/mailbox/dealWith/dealwith.vue")
const ReportManage = () => import("@/views/user/mailbox/report/reportBox/reportManage.vue")
const DepQuota = () => import("@/views/user/mailbox/depquota/depquota.vue")
const UserQuota = () => import("@/views/user/mailbox/userquota/userquota.vue")
const ChannelManage = () => import("@/views/user/mailbox/channel/channelBox/channelBox.vue")

const ReportChannel = () => import("@/views/user/mailbox/reportchannel/reportchannel.vue")


const TmAlready = () => import("@/views/user/mailbox/already/tm/tmList.vue")//腾讯已报备
const AliAlready = () => import("@/views/user/mailbox/already/ali/aliList.vue")//阿里已报备
// 已成交订单
const Closed = () => import('@/views/user/closed/closed.vue')
// 合同列表
const StorageContract = () => import('@/views/user/contract/storage/storage.vue');// 暂存列表
const ApplyContract = () => import('@/views/user/contract/apply/apply.vue');// 申请列表

const ArchivedContract = () => import('@/views/user/contract/archived/archived.vue');// 待归档列表
const AeArchiving = () => import('@/views/user/contract/archiving/archiving.vue');// 合同归档
const FiledContract = () => import('@/views/user/contract/filed/filed.vue');// 已归档列表
const Notmoney = () => import('@/views/user/contract/notmoney/notmoney.vue');// 未到款列表
const Partmoney = () => import('@/views/user/contract/partmoney/partmoney.vue');// 部分到款列表
const SealAudit = () => import("@/views/user/contr/sealAudit/sealBox/sealBox.vue")


// 订单
// const Order = () => import('@/views/user/order/order.vue');
// 订单管理
const OrderList = () => import("@/views/user/order/orderList/orderList.vue")
// const OrderDeliver = () => import("@/views/user/order/orderDeliver/orderDeliver.vue")
const TmDeliver = () => import("@/views/user/order/deliverOrder/tmDeliver/orderBox/orderBox.vue")
const DomainDeliver = () => import("@/views/user/order/deliverOrder/domainDeliver/orderBox/orderBox.vue")
const saleOrder = () => import("@/views/user/order/orderList/orderList.vue")

const Tmsaleorder = () => import("@/views/user/order/saleOrder/tmorderList.vue")
const Alisaleorder = () => import("@/views/user/order/saleOrder/aliorderList.vue")
const Domainsaleorder = () => import("@/views/user/order/saleOrder/domainorderList.vue")
// const Wysaleorder = () => import("@/views/user/order/saleOrder/domainorderList.vue")
const Wysaleorder = () => import("@/views/user/order/saleOrder/wyorderList.vue")
const Othersaleorder = () => import("@/views/user/order/saleOrder/otherorderList.vue")



const OrderDeliver = () => import("@/views/user/order/orderDeliver/orderDeliver.vue")
const AliDeliver = () => import("@/views/user/order/deliverOrder/aliDeliver/orderBox/orderBox.vue")
const AddOrder = () => import("@/views/user/order/deliverOrder/addOrder/add.vue")
const AddOrderAli = () => import("@/views/user/order/deliverOrder/addOrder/addAli.vue")
const AddOrderOther = () => import("@/views/user/order/deliverOrder/addOrder/addOther.vue")
const WyDeliver = () => import("@/views/user/order/deliverOrder/wyDeliver/orderBox/orderBox.vue")
const OtherDeliver = () => import("@/views/user/order/deliverOrder/otherDeliver/orderBox/orderBox.vue")
// const OrderDeliver = () => import("@/views/user/order/orderDeliver/orderDeliver.vue")

// 业务操作
const BizTmReport = () => import('@/views/user/business/tm-customer/tmReport.vue');// 业务操作客户操作（t）报备
const BizTmTrial = () => import('@/views/user/business/tm-customer/tmTrial.vue');// 业务操作客户操作（t）试用
const BizAliReport = () => import('@/views/user/business/ali-customer/aliReport.vue');// 业务客户操作（al）报备
const BizAliTrial = () => import('@/views/user/business/ali-customer/aliTrial.vue');// 业务客户操作（al）试用
const BizAudit = () => import('@/views/user/business/audit/audit.vue');// 业务操作业绩人工审核
const BizContract = () => import('@/views/user/business/contract/contract.vue');// 业务操作已到款合同
const ConfirmOrder = () => import('@/views/user/business/contract/confirmOrder.vue');// 确认已到款合同
const BizOrder = () => import('@/views/user/business/order/order.vue');// 业务操作订单列表
const BizContractAudit = () => import('@/views/user/business/contract-audit/contractAudit.vue');// 业务操作合同审核

// 财务
const AllMoney = () => import('@/views/user/finance/all-money/allMoney.vue');// 全部到款列表
const FinWrite = () => import('@/views/user/finance/write/write.vue');// 财务待核销列表
const FinAlreadyWrite = () => import('@/views/user/finance/already-write/alreadyWrite.vue');// 财务已核销列表
const QrcodeCollection = () => import('@/views/user/finance/qrcodeCollection/tableBox/tableBox.vue');// 财务已核销列表
const CollectionRecord = () => import('@/views/user/finance/collection/collectionBox/collectionBox.vue');// 企微收款记录
// 配置
const Configure = () => import('@/views/user/configure/configure.vue');
const Personnel = () => import('@/views/user/configure/framework/framework.vue');
const RoleConfig = () => import('@/views/user/configure/roleConfig/role.vue');
const EdotRoleConfig = () => import('@/views/user/configure/roleConfig/edit/edit.vue');
// 数据中心
const DataCenter = () => import('@/views/user/datacenter/data-box/dataBox.vue');
// 通讯录
const MailList = () => import('@/views/user/maillist/maillist.vue');

// 日志
const updateLog = () => import('@/views/user/updateLog/updateLog.vue');

// 商务费报销
const DeclareList = () => import('@/views/user/cost/declare/declareList/declareBox/declareBox.vue');
const DeclareAudit = () => import('@/views/user/cost/declare/declareAudit/declareBox/declareBox.vue');
const DeclareRecord = () => import('@/views/user/cost/declare/declareRecord/declareBox/declareBox.vue');
const ReimbList = () => import('@/views/user/cost/reimb/reimbList/reimbBox/reimbBox.vue');
const ReimbAudit = () => import('@/views/user/cost/reimb/reimbAudit/reimbBox/reimbBox.vue');
const ReimbRecord = () => import('@/views/user/cost/reimb/reimbRecord/reimbBox/reimbBox.vue');

// 工单管理
const Pending = () => import('@/views/user/cs/workOrder/pending/pending.vue');//列表

// 客户中心
const Cscontacts = () => import('@/views/user/cs/customer/contacts/contacts/contacts.vue');//联系人列表
const Cscustomer = () => import('@/views/user/cs/customer/customer/customer/customer.vue');//客户列表
// 工具
const Tool = () => import('@/views/user/cs/tool/tool.vue')
const Calculation = () => import('@/views/user/cs/tool/calculation.vue')
const CalculationChannel = () => import('@/views/user/cs/tool/calculationChannel.vue')
const CalculationNew = () => import('@/views/user/cs/tool/calculationNew.vue')
// 知识库
const Knowledge = () => import('@/views/user/cs/knowledge/knowledge.vue')

// 市场投放
const LaunchManage = () => import('@/views/user/launch/manage/manageBox/manageBox.vue')
const LaunchData = () => import('@/views/user/launch/data/dataBox/dataBox.vue')
const Recharge = () => import('@/views/user/launch/recharge/rechargeBox/rechargeBox.vue')


// 客户详情
const CustomerDetails = () => import('@/views/user/customerDetails/detailsBox/detailsBox.vue')

//已成交客户
const Tmmail = () => import('@/views/user/closedCus/tm/tmMail.vue')
const Alimail = () => import('@/views/user/closedCus/ali/aliMail.vue')
const Domain = () => import('@/views/user/closedCus/domain/domain.vue')
const Wymail = () => import('@/views/user/closedCus/wymail/wymail.vue')
const OtherProduct = () => import('@/views/user/closedCus/otherProduct/otherProduct.vue')
const PurchasedDetails = () => import('@/views/user/closedCus/detail/detail.vue')
const ClosedCusDetails = () => import('@/views/user/closedCus/details/detailsBox.vue')


// 合同管理
const ConList = () => import("@/views/user/contr/conlist/conlist.vue")
const ConInvoice = () => import("@/views/user/contr/invoice/invoice.vue")
const ConMoney = () => import("@/views/user/contr/money/money.vue")
const ConMoneyPlan = () => import("@/views/user/contr/moneyFlow/moneyFlow.vue")
const AddReturnPlan = () => import("@/views/user/contr/money/add/add.vue")
// 合同审核
const ConAudit = () => import("@/views/user/contr/conaudit/conaudit.vue")
const AuditDetails = () => import('@/views/user/contr/conaudit/audit.vue')
// 合同详情
const ConDetails = () => import('@/views/user/contr/details/detailsBox.vue')

//销售订单详情
const SaleOrderdetails = () => import('@/views/user/contr/details/saleOrderDetailsBox.vue')
// 申请发票
const ApplyInvoice = () => import('@/views/user/contr/invoice/applyInvoice/applyInvoice.vue')

// 我的发票
const MyInvoice = () => import('@/views/user/contr/invoice/myInvoice/myInvoice.vue')

// 发票

const InvoiceRecord = () => import('@/views/user/invoicerecord/invoicerecord.vue')
// 支付详情
const PayDetail = () => import("@/views/payDetail/payDetail.vue")

// 我的结算
const MyCount = () => import("@/views/user/count/myCount/myCount.vue")
const MyCountDetail = () => import("@/views/user/count/myCount/details.vue")

// 结算中心
const BusinessCount = () => import("@/views/user/count/businessCount/countBox/countBox.vue")
const BusinessCountDetails = () => import("@/views/user/count/businessCount/details/details.vue")
// 销售费用
const Salecost = () => import("@/views/user/count/saleCost/tableBox/tableBox.vue")
const SalecostPay = () => import("@/views/user/count/saleCostPay/tableBox/tableBox.vue")
const Declareinfopay = () => import("@/views/user/count/saleCostPay/pay.vue")
// 销售费用信息管理
const Salecostinfo = () => import("@/views/user/count/saleCostInfo/tableBox/tableBox.vue")
const MyAchievement = () => import("@/views/user/count/myAchievement/tableBox/tableBox.vue")
const MyAchievementTotal = () => import("@/views/user/count/myAchievementTotal/tableBox/tableBox.vue")
const MyAchievementDetails = () => import("@/views/user/count/myAchievement/details/details.vue")
//标签管理
const BaseLabel = () => import("@/views/user/label/label.vue")
const CustLabel = () => import("@/views/user/label/label.vue")

//工资表
const Wages = () => import("@/views/user/wages/wages/wages.vue")
const WagesDetail = () => import("@/views/user/wages/wages/details/details.vue")

const Mywages = () => import("@/views/user/wages/mywages/tableBox/tableBox.vue")
const ContactDetails = () => import("@/views/user/maillist/detail/details.vue")


// 产品管理
const Product = () => import("@/views/user/product/tableBox/tableBox.vue")
const Sendapply = () => import("@/views/user/workbench/audit/apply/apply.vue")
const Costdeclare = () => import('@/views/user/workbench/audit/apply/declare.vue');// 申请列表
Vue.use(VueRouter)
const routes = [
    {
        path: '/',
        redirect: '/login',
    },
    {
        path: '/login',
        component: Login,
    },
    {
        path: '/invalid',
        component: Invalid,
    },
    {
        path: '/payDetail',
        component: PayDetail,
    },
    {
        path: '/adminnavigation',
        component: AdminNavigation,
        children: [
            {
                path: '/adminframe',
                component: AdminFrame
            },
            {
                path: '/adminrole',
                component: AdminRole
            }
        ]
    },
    {
        path: '/navigation',
        component: Navigation,
        children: [
            {
                path: '/workbench',
                component: Workbench
            },
            {
                path: '/myaudit',
                component: MyAudit
            },
            {
                path: '/declareinfodetails',
                component: Declareinfodetails
            },
            {
                path: '/submitted',
                component: Submitted
            },
            {
                path: '/database',
                component: Database
            },
            {
                path: '/dataBasedetails',
                component: DataBaseDetail
            },
            {
                path: '/addDataBaseCus',
                component: AddDataBaseCus
            },
            {
                path: '/sale',
                component: Sale
            },
            {
                path: '/market',
                component: Market
            },
            {
                path: '/addclue',
                component: AddClue
            },
            {
                path: '/marketaddclue',
                component: MarketAddClue
            },
            {
                path: '/cluedetails',
                component: ClueDetails
            },
            {
                path: '/marketcluedetails',
                component: MarketClueDetails
            },
            {
                path: '/mecustomer',
                component: Latent
            },
            {
                path: '/moredetails',
                component: MoreDetails
            },

            {
                path: '/deali',
                component: Deali
            },
            {
                path: '/customerRecord',
                component: CustomerRecord
            },
            {
                path: '/customerRecordDetail',
                component: CustomerRecordDetail
            },
            {
                path: '/customerRecordHistory',
                component: CustomerRecordHistory
            },

            
            
            {
                path: '/historycust',
                component: Historycust
            },
            {
                path: '/intentionCus',
                component: IntentionCus
            },

            // 部门销售线索
            {
                path: '/depSale',
                component: DepSale
            },
            // 部门市场线索
            {
                path: '/depMarket',
                component: DepMarket
            },
            {
                path: '/depCluedetails',
                component: DepClueDetail
            },
            // 部门公海线索
            {
                path: '/depSalePublicClue',
                component: DepPublicClue
            },
            // 公司公海线索
            {
                path: '/compMarketPublicClue',
                component: CompPublicClue
            },
            // 公司市场线索
            {
                path: '/compMarketClue',
                component: CompMarketClue
            },
            // 市场线索统计
            {
                path: '/marketClueStaty',
                component: MarketClueStaty
            },
            // 阿里市场线索
            {
                path: '/aliMarketClue',
                component: AliMarketClue
            },
            // 自动分配设置
            {
                path: '/setAllocation',
                component: SetAllocation
            },
            // 阿里添加线索
            {
                path: '/addAliclue',
                component: AddAliclue
            },
            {
                path: '/aliCluedetails',
                component: AliCluedetails
            },
            {
                path: '/tmreporting',
                component: TmReporting
            },
            {
                path: '/alireporting',
                component: AliReporting
            },
            {
                path: '/aliReportingdetails',
                component: AliReportingdetails
            },
            {
                path: '/wyreporting',
                component: WyReporting
            },
            {
                path: '/wyReportingdetails',
                component: WyReportingdetails
            },
            {
                path: '/boreporting',
                component: BoReporting
            },
            {
                path: '/allreportdata',
                component: AllReportData
            },
            {
                path: '/tmDempmail',
                component: TmDempmail
            },
            {
                path: '/aliDempmail',
                component: AliDempmail
            },
            {
                path: '/wyDempmail',
                component: WyDempmail
            },
            {
                path: '/tmDetail',
                component: TmDetail
            },
            {
                path: '/dealwith',
                component: DealWith
            },
            {
                path: '/userquota',
                component: UserQuota
            },
            {
                path: '/reportchannel',
                component: ReportChannel
            },
            {
                path: '/channelinfomanage',
                component: ChannelManage
            },
            {
                path: '/reportmanage',
                component: ReportManage
            },
            {
                path: '/depquota',
                component: DepQuota
            },
            {
                path: '/readytmreprot',
                component: TmAlready
            },
            {
                path: '/readyalireprot',
                component: AliAlready
            },
            {
                path: '/closed',
                component: Closed
            },
            {
                path: '/storage',
                component: StorageContract
            },
            {
                path: '/apply',
                component: ApplyContract
            },
            {
                path: '/addcostdeclare',
                component: Costdeclare
            },
            {
                path: '/waitarchived',
                component: ArchivedContract
            },
            {
                path: '/aearchiving',
                component: AeArchiving
            },
            {
                path: '/filed',
                component: FiledContract
            },
            {
                path: '/notmoney',
                component: Notmoney
            },
            {
                path: '/partmoney',
                component: Partmoney
            },
            {
                path: '/sealAudit',
                component: SealAudit
            },
            {
                path: '/addReturnPlan',
                component: AddReturnPlan
            },
            // {
            //     path: '/order',
            //     component: Order
            // },
            {
                path: '/tmreportaudit',
                component: BizTmReport
            },
            {
                path: '/tmtrialaudit',
                component: BizTmTrial
            },
            {
                path: '/alireportaudit',
                component: BizAliReport
            },
            {
                path: '/alitrialaudit',
                component: BizAliTrial
            },
            {
                path: '/collection',
                component: BizAudit
            },
            {
                path: '/arrivalcontract',
                component: BizContract
            },
            {
                path: '/confirmorder',
                component: ConfirmOrder
            },
            {
                path: '/orderdeliver',
                component: BizOrder
            },
            {
                path: '/contractaudit',
                component: BizContractAudit
            },
            {
                path: '/waitwrite',
                component: FinWrite
            },
            {
                path: '/allmoney',
                component: AllMoney
            },
            {
                path: '/businessFlow',
                component: FinAlreadyWrite
            },
            {
                path: '/qrcodeCollection',
                component: QrcodeCollection
            },
            {
                path: '/collectionRecord',
                component: CollectionRecord
            },
            {
                path: '/configure',
                component: Configure
            },
            {
                path: '/personnel',
                component: Personnel
            },
            {
                path: '/roleConfig',
                component: RoleConfig
            },
            {
                path: '/editRoleaddConfig',
                component: EdotRoleConfig
            },
            {
                path: '/datacenter',
                component: DataCenter
            },
            {
                path: '/maillist',
                component: MailList
            },
            {
                path: '/contractdetails',
                component: ContactDetails
            },
            {
                path: '/updatelog',
                component: updateLog
            },
            {
                path: '/reimbList',
                component: ReimbList
            },
            {
                path: '/reimbAudit',
                component: ReimbAudit
            },
            {
                path: '/reimbRecord',
                component: ReimbRecord
            },
            {
                path: '/declareList',
                component: DeclareList
            },
            {
                path: '/declareAudit',
                component: DeclareAudit
            },
            {
                path: '/declareRecord',
                component: DeclareRecord
            },
            {
                path: '/pending',
                component: Pending
            },
            {
                path: '/cscontacts',
                component: Cscontacts
            },
            {
                path: '/cscustomer',
                component: Cscustomer
            },
            {
                path: '/tool',
                component: Tool
            },
            {
                path: '/calculation',
                component: Calculation
            },
            {
                path: '/calculationChannel',
                component: CalculationChannel
            },
            {
                path: '/calculationNew',
                component: CalculationNew
            },
            {
                path: '/knowledge',
                component: Knowledge
            },
            // 市场投放
            {
                path: "/launchmanage",
                component: LaunchManage
            },
            {
                path: "/launchdata",
                component: LaunchData
            },
            {
                path: "/customerdetails",
                component: CustomerDetails
            },
            {
                path: "/purchasedDetails",
                component: PurchasedDetails
            },
            {
                path: "/closedcusDetails",
                component: ClosedCusDetails
            },
            {
                path: "/recharge",
                component: Recharge
            },
            {
                path: "/tmmail",
                component: Tmmail
            },
            {
                path: "/tencentmailbox",
                component: Tmmail
            },
            {
                path: "/alimail",
                component: Alimail
            },
            {
                path: "/alimailbox",
                component: Alimail
            },
            {
                path: "/domain",
                component: Domain
            },
            {
                path: "/domainmailbox",
                component: Domain
            },
            {
                path: "/wymail",
                component: Wymail
            },
            {
                path: "/otherProduct",
                component: OtherProduct
            },
            {
                path: "/neteasemailbox",
                component: Wymail
            },
            // 合同管理
            {
                path: "/conlist",
                component: ConList
            },
            {
                path: "/money",
                component: ConMoney
            },
            {
                path: "/invoice",
                component: ConInvoice
            },
            {
                path: "/myinvoice",
                component: MyInvoice
            },
            {
                path: "/invoicerecord",
                component: InvoiceRecord
            },
            {
                path: "/moneyFlow",
                component: ConMoneyPlan
            },
            // 合同详情
            {
                path: "/condetails",
                component: ConDetails
            },
            // 销售订单详情
            {
                path: "/saleOrderdetails",
                component: SaleOrderdetails
            },
            
            // 订单管理
            {
                path: "/saleOrder",
                component: saleOrder
            },
            {
                path: "/tmsaleorder",
                component: Tmsaleorder
            },
            {
                path: "/alisaleorder",
                component: Alisaleorder
            },
            {
                path: "/domainsaleorder",
                component: Domainsaleorder
            },
            {
                path: "/wysaleorder",
                component: Wysaleorder
            },
            {
                path: "/othersaleorder",
                component: Othersaleorder
            },
            {
                path: "/tmDeliver",
                component: TmDeliver
            },
            {
                path: "/aliDeliver",
                component: AliDeliver
            },
            {
                path: "/wydeliver",
                component: WyDeliver
            },
            {
                path: "/otherdeliver",
                component: OtherDeliver
            },
            {
                path: "/addorder",
                component: AddOrder
            },
            {
                path: "/addorderAli",
                component: AddOrderAli
            },
            {
                path: "/addOrderOther",
                component: AddOrderOther
            },
            
            {
                path: "/domainDeliver",
                component: DomainDeliver
            },
            {
                path: "/addApplyinvoice",
                component: ApplyInvoice
            },
            {
                path: "/conaudit",
                component: ConAudit
            },
            {
                path: "/auditdetails",
                component: AuditDetails
            },
            {
                path: "/myCount",
                component: MyCount
            },
            {
                path: "/myCountDetail",
                component: MyCountDetail
            },
            {
                path: "/businessCount",
                component: BusinessCount
            },
            {
                path: "/businessCountdetails",
                component: BusinessCountDetails
            },
            {
                path: "/salecost",
                component: Salecost
            },
            {
                path: "/salecostpay",
                component: SalecostPay
            },
            {
                path: "/declareinfopaydetails",
                component: Declareinfopay
            },
            {
                path: "/salecostinfo",
                component: Salecostinfo
            },
            {
                path: "/myachievement",
                component: MyAchievementTotal
            },
            {
                path: "/myachievementlistdetails",
                component: MyAchievement
            },
            {
                path: "/myachievementdetails",
                component: MyAchievementDetails
            },
            {
                path: "/baseLabel",
                component: BaseLabel
            },
            {
                path: "/custLabel",
                component: CustLabel
            },
            {
                path: "/wages",
                component: Wages
            },
            {
                path: "/wagesDetail",
                component: WagesDetail
            },
            {
                path: "/mywages",
                component: Mywages
            },
            {
                path: "/product",
                component: Product
            },
            {
                path: "/sendapply",
                component: Sendapply
            },
        ]
    }
]
const router = new VueRouter({
    mode: 'history',
    routes
})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

export default router