export default {
    // 报备状态
    reportStatus(status) {
        switch (status) {
            case 1:
                return "未报备"
            case 2:
                return "申请中"
            case 3:
                return "已报备"
            case 4:
                return "驳回"
            case 5:
                return "已过期"
            case 6:
                return "已保存"
            case 7:
                return "已撤销"
            case 8:
                return "报备申请中"
            case 9:
                return "报备处理中"
            case 10:
                return "报备失败"
            case 11:
                return "报备延期申请中"
            case 12:
                return "报备延期处理中"
            case 13:
                return "放弃报备申请中"
            case 14:
                return "放弃报备处理中"
            case 15:
                return "添加域名申请中"
            case 16:
                return "添加域名处理中"
            case 17:
                return "已放弃"
            case 18:
                return "已成交"
            case 19:
                return "报备到期"
            case 20:
                return "报备延期申请中(Boss)"
            case 21:
                return "报备延期处理中(Boss)"
        }
    },
    // 试用状态
    trialStatus(status) {
        switch (status) {
            case 1:
                return "未试用"
            case 2:
                return "申请试用"
            case 3:
                return "试用中"
            case 4:
                return "试用到期"
            case 5:
                return "试用结束"
            case 6:
                return "驳回"
            case 7:
                return "试用申请中"
            case 8:
                return "申请试用处理中"
            case 9:
                return "申请失败"
            case 10:
                return "停止试用申请中"
            case 11:
                return "停止试用处理中"
            case 12:
                return "已中止试用"
            case 13:
                return "试用延期申请中"
            case 14:
                return "试用延期处理中"
            case 15:
                return "变更试用申请中"
            case 16:
                return "变更试用处理中"
            case 17:
                return "已成交"
            case 18:
                return "重新试用申请中"
            case 19:
                return "重新试用处理中"
            default:
                return '- -'
        }
    },
    tmFilingList() {
        let arr = [
            {
                label: "伽搜",
                value: 1
            },
            {
                label: "布谷",
                value: 2
            },
            {
                label: "品服",
                value: 3
            },
            {
                label: "连岳",
                value: 4
            },
            {
                label: "张作鹏",
                value: 5
            },
            {
                label: "企邮会",
                value: 6
            },
            {
                label: "非线性",
                value: 7
            },
            {
                label: "上海瑞外纽",
                value: 8
            },
            {
                label: "成都预明",
                value: 9
            },
            {
                label: "辰宿",
                value: 10
            },

        ]
        return arr
    },
    tmFiling(i) {
        switch (i) {
            case 1:
                return "伽搜"
            case 2:
                return "布谷"
            case 3:
                return "品服"
            case 4:
                return "连岳"
            case 5:
                return "张作鹏"
            case 6:
                return "企邮会"
            case 7:
                return "非线性"
            case 8:
                return "上海瑞外纽"
            case 9:
                return "成都预明"
            case 10:
                return "辰宿"
            case 0:
                return "- -"
        }
    },
    // 阿里报备库
    aliFiling(status) {
        switch (status) {
            case 11:
                return '钱邮'
            case 12:
                return "云梦"
            case 13:
                return "山总"
            case 14:
                return "华中"
            case 15:
                return "云璞"
            default:
                return '- -'
        }
    },
    estimateNumber() {
        let arr = [
            {
                label: "5-20",
                value: 1
            },
            {
                label: "21-50",
                value: 2
            },
            {
                label: "51-100",
                value: 3
            },
            {
                label: "101-250",
                value: 4
            },
            {
                label: "251-500",
                value: 5
            },
            {
                label: "501-1000",
                value: 6
            },
            {
                label: "1001-2500",
                value: 7
            },
            {
                label: "2501-5000",
                value: 8
            },
            {
                label: "5000+",
                value: 9
            },
        ]
        return arr
    },
    echoEstimateNumber(i) {
        switch (i) {
            case 1:
                return "5-20"
            case 2:
                return "21-50"
            case 3:
                return "51-100"
            case 4:
                return "101-250"
            case 5:
                return "251-500"
            case 6:
                return "501-1000"
            case 7:
                return "1001-2500"
            case 8:
                return "2501-5000"
            case 9:
                return "5000+"
            default:
                return '- -'
        }
    },
    estimateTerm() {
        let arr = [
            {
                label: "1-2",
                value: 1
            },
            {
                label: "3-4",
                value: 2
            },
            {
                label: "5-6",
                value: 3
            },
            {
                label: "6+",
                value: 4
            },
        ]
        return arr
    },
    echoEstimateTerm(i) {
        switch (i) {
            case 1:
                return "1-2"
            case 2:
                return "3-4"
            case 3:
                return "5-6"
            case 4:
                return "6+"
            default:
                return '- -'
        }
    },
    // 阿里报备库选择
    aliReportSe() {
        let arr = [
            {
                label: "钱邮",
                value: 11
            },
            {
                label: "云梦",
                value: 12
            },
            {
                label: "山总",
                value: 13
            },
            {
                label: "华中",
                value: 14
            },
            {
                label: "云璞",
                value: 15
            },
        ]
        return arr
    },
    planType(val) {
        if (val == 1) {
            return "扩容"
        }
        if (val == 2) {
            return "扩容（助手侧）"
        }
        if (val == 3) {
            return "续费"
        }
        if (val == 4) {
            return "续费（助手侧）"
        }
        if (val == 5) {
            return "正式（助手侧）"
        }
        if (val == 6) {
            return "正式"
        }
        return '- -'
    },
    planTypeSel() {
        let arr = [
            {
                label: "扩容",
                value: 1
            },
            {
                label: "扩容（助手侧）",
                value: 2
            },
            {
                label: "续费",
                value: 3
            },
            {
                label: "续费（助手侧）",
                value: 4
            },
            {
                label: "正式（助手侧）",
                value: 5
            },
            {
                label: "正式",
                value: 6
            }
        ]
        return arr
    },
    planTypeAli(val) {
        if (val == 1) {
            return "扩容"
        }
        if (val == 2) {
            return "续费"
        }
        if (val == 3) {
            return "正式"
        }
        return '- -'
    },
    planTypeAliSel() {
        let arr = [
            {
                label: "扩容",
                value: 1
            },
            {
                label: "续费",
                value: 2
            },
            {
                label: "正式",
                value: 3
            },
        ]
        return arr
    },
    planTypeDomain(val) {
        if (val == 1) {
            return "新购"
        }
        if (val == 2) {
            return "续费"
        }

        return '- -'
    },
    planTypeDomainSel() {
        let arr = [
            {
                label: "新购",
                value: 1
            },
            {
                label: "续费",
                value: 2
            },

        ]
        return arr
    },
    versionAli(val) {
        if (val == 1) {
            return "标准版"
        }
        if (val == 2) {
            return "集团版"
        }
        if (val == 3) {
            return "尊享版"
        }
        return '- -'
    },
}