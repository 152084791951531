export default {
    flexColumnWidth(str, tableData, flag = 'max') {
        // console.log(str);
        // console.log(tableData);
        str = str + '';
        let columnContent = '';
        if (
            !tableData ||
            !tableData.length ||
            tableData.length === 0 ||
            tableData === undefined
        ) {
            return;
        }
        if (!str || !str.length || str.length === 0 || str === undefined) {
            return;
        }
        if (flag === 'equal') {
            // 获取该列中第一个不为空的数据(内容)
            for (let i = 0; i < tableData.length; i++) {
                if (tableData[i][str].length > 0) {
                    // // console.log('该列数据[0]:', tableData[0][str])
                    columnContent = tableData[i][str];
                    break;
                }
            }
        } else {
            // 获取该列中最长的数据(内容)
            let index = 0;
            for (let i = 0; i < tableData.length; i++) {
                if (tableData[i][str] === null) {
                    return;
                }
                const now_temp = tableData[i][str] + '';
                const max_temp = tableData[index][str] + '';
                if (now_temp.length > max_temp.length) {
                    index = i;
                }
            }
            columnContent = tableData[index][str];
        }
        // // console.log('该列数据[i]:', columnContent)
        // 以下分配的单位长度可根据实际需求进行调整
        let flexWidth = 0;
        // console.log(columnContent);
        for (let char of columnContent) {
            if ((char >= 'A' && char <= 'Z') || (char >= 'a' && char <= 'z')) {
                // 如果是英文字符，为字符分配8个单位宽度
                flexWidth += 12;
            } else if (char >= '\u4e00' && char <= '\u9fa5') {
                // 如果是中文字符，为字符分配15个单位宽度
                flexWidth += 20;
            } else {
                // 其他种类字符，为字符分配8个单位宽度
                flexWidth += 12;
            }
        }

        // console.log(flexWidth);
        if (flexWidth <= 80) {
            // 设置最小宽度
            flexWidth = 150;
        }
        return flexWidth + 'px';
    },
}