export default {

     formatWithArray(num) {
          const res=num.toString().replace(/\d+/, function(n){ // 先提取小数点前后的整数部分
               //(\d)(?=(\d{3})正则的意思是匹配连续三个数结尾的数字，例如1234，1后面有234连续三个数的数字，就可以匹配上，匹配的是1这个数字。如果是123，因为1后面是两位数，所以就匹配不上了
                 return n.replace(/(\d)(?=(\d{3})+$)/g,function($1){
                   return $1+",";
                  });
                })
                return res;
        }
         
}